import { Button, Input, Form, Row, Col, Select, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { UploadRequestOption } from "../../../../node_modules/rc-upload/lib/interface";

import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  IphoneButton,
  IurlButton,
  IShareNameCardButton,
} from "../../../interface/flexMessage";
import { fontSize } from "../../../config/setting";

import classes from "./NameCard.module.sass";
import PopoverPicker from "../../PopoverPicker";
import TextInput from "./TextInput";

import { uploadImageToS3 } from "../../../api";
import { fontSizeType, useAppStateStore } from "../../../store/appState";
import NameCardButton from "./NameCardButton";

type IBusinessCardProps = {
  id: string;
  cardHashID?: string;
};

const BusinessCard = ({ id, cardHashID }: IBusinessCardProps) => {
  const [form] = Form.useForm();
  const { APP, USER, NAMECARD_DATA, BRAND } = useAppStateStore();
  const { currentConfig } = APP;
  const { userState } = USER;
  const { accessToken } = userState;
  const { brandState } = BRAND; // 24.11.21 SCH ★
  const {
    nameCardData,
    setNameCardV1Data,
    setNameCardV1Setting,
    setNameCardV1Form,
  } = NAMECARD_DATA;
  const { nameCardV1Data, nameCardV1Setting } = nameCardData;

  const { Option } = Select;
  const [buttonType, setButtonType] = useState<
    "urlButton" | "phoneButton" | "shareNameCardButton"
  >("urlButton");

  const thisPageFlexMessageIndexRef = useRef(0);

  const currentIndex = useMemo(() => {
    return nameCardV1Data.findIndex((data) => data.id === id);
  }, [nameCardV1Data, id]);

  const thisPageTextInput = () => {
    if (nameCardV1Data && thisPageFlexMessageIndexRef.current !== undefined) {
      const temp =
        nameCardV1Data[thisPageFlexMessageIndexRef.current].IntroText;
      return temp.filter((text) => text._key === id);
    }
  };
  const thisPageTextInputNum = thisPageTextInput()?.length;
  //filter this page nameCard button[] data
  const thisPageTextFlexButton = () => {
    if (nameCardV1Data && thisPageFlexMessageIndexRef.current !== undefined) {
      const temp = nameCardV1Data[thisPageFlexMessageIndexRef.current].button;
      return temp.filter((btn) => btn._key === id);
    }
  };
  // define this page nameCard button[] length
  const thisPageTextFlexButtonNum = thisPageTextFlexButton()?.length;

  const addIntroText = () => {
    if (
      nameCardV1Data &&
      nameCardV1Data[thisPageFlexMessageIndexRef.current ?? 0].IntroText
        ?.length >= 5
    )
      return;
    if (nameCardV1Data) {
      const _flexMessage = Array.from(nameCardV1Data);
      _flexMessage[thisPageFlexMessageIndexRef.current ?? 0].IntroText.push({
        _key: id,
        id: thisPageTextInputNum ?? 0,
        inputValue: "",
      });
      setNameCardV1Data && setNameCardV1Data(_flexMessage);
    }
  };

  const urlButtonValue: IurlButton = {
    _key: id,
    id: thisPageTextFlexButtonNum ?? 0,
    buttonText: "按鈕文字(可編輯)",
    buttonUrl: brandState.liff_id_promo ?? "https://line.me/zh-hant/",
  }; // 24.11.21 SCH ★ (default 'https://liff.line.me/'+liff_id+/?)

  const phoneButtonValue: IphoneButton = {
    _key: id,
    id: thisPageTextFlexButtonNum ?? 0,
    buttonText: "聯絡電話",
    phoneNumber: "",
  };

  const shareNameCardButtonValue: IShareNameCardButton = {
    _key: id,
    id: thisPageTextFlexButtonNum ?? 0,
    buttonText: "分享名片",
    buttonUrl: cardHashID
      ? `https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/eaglesendflexMsg?hash_id=${cardHashID}`
      : `https://liff.line.me/${currentConfig.REACT_APP_LIFFID}/eaglesendflexMsg?hash_id=`,
  };

  const addButton = () => {
    if (thisPageTextFlexButtonNum && thisPageTextFlexButtonNum >= 3) return;

    if (nameCardV1Data && thisPageFlexMessageIndexRef.current !== undefined) {
      const _flexMessage = Array.from(nameCardV1Data);
      const newIndex =
        _flexMessage[thisPageFlexMessageIndexRef.current].button.length;

      const newButton = {
        _key: id,
        id: thisPageTextFlexButtonNum ?? 0,
        content:
          buttonType === "urlButton"
            ? urlButtonValue
            : buttonType === "phoneButton"
              ? phoneButtonValue
              : shareNameCardButtonValue,
        type: buttonType,
      };

      _flexMessage[thisPageFlexMessageIndexRef.current].button.push(
        newButton as any
      );

      // 設置表單值
      form.setFieldsValue({
        [`${buttonType === "shareNameCardButton" ? "shareNameCardButtonText" : "buttonText"}-${id}-${newIndex}`]:
          buttonType === "shareNameCardButton"
            ? shareNameCardButtonValue.buttonText
            : buttonType === "urlButton"
              ? urlButtonValue.buttonText
              : phoneButtonValue.buttonText,
        [`${
          buttonType === "urlButton"
            ? "buttonUrl"
            : buttonType === "phoneButton"
              ? "phoneNumber"
              : "shareNameCardButtonUrl"
        }-${id}-${newIndex}`]:
          buttonType === "shareNameCardButton"
            ? shareNameCardButtonValue.buttonUrl
            : buttonType === "urlButton"
              ? urlButtonValue.buttonUrl
              : phoneButtonValue.phoneNumber,
      });

      setNameCardV1Data && setNameCardV1Data(_flexMessage);
    }
  };

  /**
   * 處理名片標題名稱變動
   * @param value input onChange function return value
   */
  const handleNameChange = (value: string) => {
    if (nameCardV1Data && thisPageFlexMessageIndexRef.current !== undefined) {
      const _flexMessage = nameCardV1Data && Array.from(nameCardV1Data);
      _flexMessage[thisPageFlexMessageIndexRef.current].title = value;
      if (setNameCardV1Data) setNameCardV1Data(_flexMessage);
    }
  };

  const handleImgUrlChange = (value: string) => {
    if (nameCardV1Data && thisPageFlexMessageIndexRef.current !== undefined) {
      const _flexMessage = Array.from(nameCardV1Data);
      _flexMessage[thisPageFlexMessageIndexRef.current].imageUrl.map((item) => {
        if (item._key === id) item.imageUrl = value;
      });

      setNameCardV1Data && setNameCardV1Data(_flexMessage);
    }
  };

  const handleTitleColorChange = (e: string) => {
    if (nameCardV1Data && thisPageFlexMessageIndexRef.current !== undefined) {
      const _flexMessage = Array.from(nameCardV1Data);
      _flexMessage[thisPageFlexMessageIndexRef.current].titleColor = e;
      setNameCardV1Data && setNameCardV1Data(_flexMessage);
    }
  };
  const handleIntroTextColorChange = (e: string) => {
    const _flexMessage = nameCardV1Data && [...nameCardV1Data];
    const _index = _flexMessage?.findIndex((item) => item.id === id);
    if (_flexMessage && _index !== undefined)
      _flexMessage[_index].introTextColor = e;
    if (_flexMessage && setNameCardV1Data) setNameCardV1Data(_flexMessage);
  };
  const handleCardBackgroundColorChange = (e: string) => {
    const _flexMessage = nameCardV1Data && [...nameCardV1Data];
    const _index = _flexMessage?.findIndex((item) => item.id === id);
    if (_flexMessage && _index !== undefined)
      _flexMessage[_index].cardBackgroundColor = e;
    if (_flexMessage && setNameCardV1Data) setNameCardV1Data(_flexMessage);
  };

  /**
   * 上傳圖片到 AWS S3
   * @param option Ant Design Upload component option
   */
  const uploadImgHandler = async (option: UploadRequestOption) => {
    const { file, onProgress, onError, onSuccess } = option;

    //傳給 onError 錯誤處理事件
    const errorEvent = new ProgressEvent("error", {
      lengthComputable: false,
      loaded: 0,
      total: 0,
    });

    // 將圖片檔案新增到 FormData
    const resultFile = new FormData();
    resultFile.append("image", file);

    try {
      if (accessToken) {
        const { data } = await uploadImageToS3(accessToken, resultFile);
        if (data)
          if (data.data.success) {
            // 處理上傳成功的邏輯
            console.log("文件上傳成功");
            const { image_url } = data.data.data;
            onProgress && onProgress({ percent: 100 });
            onSuccess && onSuccess(image_url);
          } else {
            // 處理上傳失敗的邏輯
            console.error("文件上傳失敗", data.data.message);
            onError && onError(errorEvent);
          }
      }
    } catch (error) {
      // 處理上傳失敗的邏輯
      console.error("文件上傳失敗", error);
      onError && onError(errorEvent);
    }
  };

  // Ant Design Upload component props
  const props: UploadProps = {
    name: "image",
    accept: "image/png, image/jpeg",
    multiple: false,
    customRequest: (option) => uploadImgHandler(option),
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} 圖片上傳成功.`);
        console.log(info.file.url);
        console.log(info);
        handleImgUrlChange(info.file.response);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} 圖片上傳失敗.`);
      }
    },
  };

  useEffect(() => {
    if (form) {
      setNameCardV1Form(id, form);
    }
  }, [form]);

  // 更新 ref
  useEffect(() => {
    if (currentIndex !== -1) {
      thisPageFlexMessageIndexRef.current = currentIndex;
    }
  }, [currentIndex]);

  // 更新表單值
  useEffect(() => {
    form.setFieldsValue({
      cardTitle: nameCardV1Setting.cardTitle,
      altText: nameCardV1Setting.altText,
      titleFontSize: nameCardV1Setting.titleFontSize,
      describeFontSize: nameCardV1Setting.describeFontSize,
    });
  }, [nameCardV1Setting]);

  return (
    <div className={classes.container}>
      <Form
        form={form}
        className={classes.form}
        layout="vertical"
        initialValues={{
          cardTitle: nameCardV1Setting.cardTitle,
          altText: nameCardV1Setting.altText,
          title: nameCardV1Data
            ? nameCardV1Data[thisPageFlexMessageIndexRef.current ?? 0].title
            : "姓名/抬頭標題",
          cardBackgroundColor:
            nameCardV1Data === null
              ? undefined
              : nameCardV1Data[thisPageFlexMessageIndexRef.current ?? 0]
                  .cardBackgroundColor,
          titleFontSize: nameCardV1Setting.titleFontSize,
          describeFontSize: nameCardV1Setting.describeFontSize,
          titleColor:
            nameCardV1Data === null
              ? undefined
              : nameCardV1Data[thisPageFlexMessageIndexRef.current ?? 0]
                  .titleColor,
          introTextColor:
            nameCardV1Data === null
              ? undefined
              : nameCardV1Data[thisPageFlexMessageIndexRef.current ?? 0]
                  .introTextColor,

          ...(nameCardV1Data &&
          thisPageFlexMessageIndexRef.current !== undefined
            ? nameCardV1Data[
                thisPageFlexMessageIndexRef.current
              ].IntroText.reduce(
                (acc, item, index) => ({
                  ...acc,
                  [`describeText-${item._key}-${index}`]: item.inputValue,
                }),
                {}
              )
            : {}),
          ...(nameCardV1Data &&
          thisPageFlexMessageIndexRef.current !== undefined
            ? nameCardV1Data[thisPageFlexMessageIndexRef.current].button.reduce(
                (acc, item, index) => {
                  if (item.type === "urlButton") {
                    return {
                      ...acc,
                      [`buttonText-${item._key}-${index}`]:
                        item.content.buttonText,
                      [`buttonUrl-${item._key}-${index}`]:
                        item.content.buttonUrl,
                    };
                  }
                  if (item.type === "phoneButton") {
                    return {
                      ...acc,
                      [`phoneNumber-${item._key}-${index}`]:
                        item.content.phoneNumber,
                    };
                  }
                  if (item.type === "shareNameCardButton") {
                    return {
                      ...acc,
                      [`shareNameCardButtonText-${item._key}-${index}`]:
                        item.content.buttonText,
                      [`shareNameCardButtonUrl-${item._key}-${index}`]:
                        item.content.buttonUrl,
                    };
                  }
                  return acc;
                },
                {}
              )
            : {}),
        }}
      >
        <Row>
          <Col lg={8} md={24} sm={24} xs={24}>
            <Col span={24} className={classes.col}>
              <Col span={24} className={classes.col}>
                <Form.Item
                  label={<span className={classes.label}>1.名片封面圖片</span>}
                >
                  <Upload {...props}>
                    <Button icon={<UploadOutlined rev={""} />}>上傳圖片</Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Form.Item
                name="cardTitle"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject("請輸入電子名片名稱");
                      }
                      // 檢查是否包含任何空白字元
                      if (/\s/.test(value)) {
                        return Promise.reject("名稱不可包含空白字元");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                label={<span className={classes.label}>2.電子名片名稱</span>}
              >
                <Input
                  className={classes.input}
                  placeholder="這個電子名片的名稱"
                  onChange={(e) =>
                    setNameCardV1Setting &&
                    setNameCardV1Setting({
                      ...nameCardV1Setting,
                      cardTitle: e.target.value,
                    })
                  }
                  value={nameCardV1Setting.cardTitle}
                />
              </Form.Item>

              <Form.Item
                name="altText"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject("請輸入訊息通知文字");
                      }
                      // 檢查是否包含任何空白字元
                      if (/\s/.test(value)) {
                        return Promise.reject("訊息通知文字不可包含空白字元");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                label={
                  <span className={classes.label}>3.LINE 訊息通知文字</span>
                }
              >
                <Input
                  className={classes.input}
                  placeholder="將顯示於LINE 訊息通知文字"
                  onChange={(e) =>
                    setNameCardV1Setting &&
                    setNameCardV1Setting({
                      ...nameCardV1Setting,
                      altText: e.target.value,
                    })
                  }
                  value={nameCardV1Setting.altText}
                />
              </Form.Item>
              <Form.Item
                name="title"
                rules={[
                  {
                    required: true,
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject("請輸入姓名/抬頭標題");
                      }
                      // 檢查是否包含任何空白字元
                      if (/\s/.test(value)) {
                        return Promise.reject("姓名/抬頭標題不可包含空白字元");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                label={<span className={classes.label}>4.姓名/抬頭標題</span>}
              >
                <Input
                  type="text"
                  placeholder="您的大名"
                  onChange={(e) => handleNameChange(e.target.value)}
                  value={
                    nameCardV1Data
                      ? nameCardV1Data[thisPageFlexMessageIndexRef.current ?? 0]
                          .title
                      : "姓名/抬頭標題"
                  }
                />
              </Form.Item>
              <div className={classes.titleSetting}>
                <Form.Item
                  className={classes.titleFontSize}
                  label="文字大小"
                  name="titleFontSize"
                >
                  <Select
                    size="large"
                    defaultValue={"lg"}
                    onChange={(e: fontSizeType) => {
                      setNameCardV1Setting &&
                        setNameCardV1Setting({
                          ...nameCardV1Setting,
                          titleFontSize: e,
                        });
                    }}
                    value={nameCardV1Setting?.titleFontSize}
                  >
                    {fontSize.map((item, id) => (
                      <Option key={id} value={item}>
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="文字顏色"
                  name="titleColor"
                  className={classes.titleColorPicker}
                >
                  <PopoverPicker
                    color={
                      nameCardV1Data === null
                        ? undefined
                        : nameCardV1Data[
                            thisPageFlexMessageIndexRef.current ?? 0
                          ].titleColor
                    }
                    id={id}
                    onChange={(e) => handleTitleColorChange(e)}
                  />
                </Form.Item>
              </div>

              <Col span={24} className={classes.col}>
                {thisPageTextInput()?.map((_, index) => (
                  <TextInput
                    key={index}
                    pageIndex={thisPageFlexMessageIndexRef.current}
                    elementIndex={index}
                    id={id}
                  />
                ))}

                <div className={classes.textSetting}>
                  <div className={classes.titleFontSize}>
                    <Form.Item
                      name="describeFontSize"
                      label="文字大小"
                      className={classes.titleFontSize}
                    >
                      <Select
                        size="large"
                        defaultValue={"lg"}
                        onChange={(e: fontSizeType) =>
                          setNameCardV1Setting &&
                          setNameCardV1Setting({
                            ...nameCardV1Setting,
                            describeFontSize: e,
                          })
                        }
                        value={nameCardV1Setting?.describeFontSize}
                      >
                        {fontSize.map((item, id) => (
                          <Option key={id} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <Form.Item
                    className={classes.titleColorPicker}
                    name="introTextColor"
                    label="文字顏色"
                  >
                    <PopoverPicker
                      color={
                        nameCardV1Data === null
                          ? undefined
                          : nameCardV1Data[
                              thisPageFlexMessageIndexRef.current ?? 0
                            ].introTextColor
                      }
                      id={id}
                      onChange={(e) => handleIntroTextColorChange(e)}
                    />
                  </Form.Item>
                </div>

                <div>
                  <Button
                    type="primary"
                    onClick={addIntroText}
                    className={classes.button}
                  >
                    <div className={classes.buttonWord}>新增說明文字</div>
                  </Button>
                </div>
              </Col>
            </Col>

            <Col span={24} className={classes.col}>
              <div className={classes.textlable}>
                <label>6.按鈕連結</label>
              </div>
              {thisPageFlexMessageIndexRef.current !== undefined &&
                nameCardV1Data &&
                nameCardV1Data[thisPageFlexMessageIndexRef.current].button.map(
                  (btn, index) => {
                    console.log("btn", btn);
                    return (
                      <NameCardButton
                        id={id}
                        // id={btn._key}
                        elementIndex={index}
                        thisPageFlexMessageIndex={
                          thisPageFlexMessageIndexRef.current ?? 0
                        }
                        buttonType={btn.type}
                      />
                    );
                  }
                )}

              <div className={classes.addButtonContainer}>
                <div>
                  <Button
                    type="primary"
                    className={classes.button}
                    onClick={addButton}
                  >
                    <div className={classes.buttonWord}>新增按鈕</div>
                  </Button>
                </div>
                <div>
                  <Select
                    size="middle"
                    defaultValue={"urlButton"}
                    onChange={(
                      _buttonType:
                        | "urlButton"
                        | "phoneButton"
                        | "shareNameCardButton"
                    ) => setButtonType(_buttonType)}
                  >
                    <Option value={"urlButton"}>超連結按鈕</Option>
                    <Option value={"phoneButton"}>手機電話按鈕</Option>
                    <Option value={"shareNameCardButton"}>分享名片按鈕</Option>
                  </Select>
                </div>
              </div>
            </Col>
            <Col span={24} className={classes.colorPickerlabel}>
              <Form.Item label="7.卡片底色" name="cardBackgroundColor">
                <PopoverPicker
                  color={
                    nameCardV1Data === null
                      ? undefined
                      : nameCardV1Data[thisPageFlexMessageIndexRef.current ?? 0]
                          .cardBackgroundColor
                  }
                  id={id}
                  onChange={(e) => handleCardBackgroundColorChange(e)}
                />
              </Form.Item>
            </Col>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
export default BusinessCard;
