import Axios, { AxiosError } from "axios";
import { getCurrentConfig } from "../utils/config";
import { userStateType } from "../store/appState";
//----------------- interface / type ----------------------------------------
export interface Response<T> {
  error?: AxiosError<{
    message: string;
    errors: { [key: string]: string };
  }>;
  data?: T;
}

type RegisterFormData = {
  mobile_number?: string;
};

export interface LoginType {
  data: {
    token: string;
    expires_in: string;
    token_type: string;
    // 一般用戶 1 正式用戶 0
    is_common: 0 | 1;
    // is_normal: boolean;
    // user_id: number;
    // mobile_number: string;
    // // 已經登入為 1 尚未登入為 0
    // isLoggedIn: 0 | 1;
    // // 已經是會員 1 不是會員 0
    // isMember: 0 | 1;
    // // 已經有紀錄 1 尚未紀錄 0
    // logging: 0 | 1;
    // line_id: string;
    // nickname: string;
    // realname: string;
  };
}

//-----------------------------------------------------------
//api 前綴

const currentConfig = getCurrentConfig();

// const apiURL = 'https://api-dev.zinbin.net'; // 22.12.13 by Mike
const apiURL = currentConfig?.REACT_APP_API_URL; // 24.10.29 SCH ★

export const axios = Axios.create({
  baseURL: `${apiURL}`,
});

/**
 * 註冊api
 * @param lineToken line ID Token
 */
export const register = async (
  lineIdToken: string,
  brandID: number,
  formData: RegisterFormData
): Promise<Response<LoginType>> => {
  try {
    const { data } = await axios.request<LoginType>({
      url: "/api/member/register",
      method: "POST",
      data: {
        brand_id: brandID,
        ...formData,
      },
      headers: {
        Authorization: `Bearer ${lineIdToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 會員登入
 * @param lineToken line ID Token
 */
export const login = async (
  brandID: number,
  lineIdToken: string,
  promo_hash?: string,
  promo_slug?: string
): Promise<Response<LoginType>> => {
  try {
    const { data } = await axios.request<LoginType>({
      url: "/api/member/login",
      method: "POST",
      data: {
        brand_id: brandID,
        promo_hash: promo_hash ? promo_hash : null,
        promo_slug: promo_slug ? promo_slug : null,
      },
      headers: {
        Authorization: `Bearer ${lineIdToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 會員登入
 * @param lineToken line ID Token
 */
export const godmodLogin = async (
  brandID: number,
  gm_token: string,
  param: string,
  promo_hash?: string,
  promo_slug?: string
): Promise<Response<LoginType>> => {
  try {
    const { data } = await axios.request<LoginType>({
      url: "/api/member/login",
      method: "POST",
      data: {
        brand_id: brandID,
        gm_token: gm_token,
        param: param,
        promo_hash: promo_hash ? promo_hash : null,
        promo_slug: promo_slug ? promo_slug : null,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * eagle會員登入
 * @param brandID 品牌代號
 * @param lineToken line ID Token
 * @param mobileNumber 電話號碼
 * @param eagle eagle 會員模式代號
 */
export const eagleLogin = async (
  brandID: number,
  lineIdToken: string,
  mobileNumber: string,
  eagle: number
): Promise<Response<LoginType>> => {
  try {
    const { data } = await axios.request<LoginType>({
      url: "/api/member/login",
      method: "POST",
      data: {
        brand_id: brandID,
        mobile_number: mobileNumber,
        eagle: eagle,
      },
      headers: {
        Authorization: `Bearer ${lineIdToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface nameCardType {
  card_id: number;
  card_type: 1 | 2;
  card_title: string;
  card_content: string;
  brand_id: number;
  line_id: string;
  mobile_number: string;
  created_at: string;
  updated_at: string;
  message_text: string;
  image_urls: string[];
  card_idx: number | null;
  user_category_id: number | null;
  category_name: string | null;
  user_folder_id: number | null;
  folder_name: string | null;
}
export interface queryThisNameCardType {
  data: {
    card_id: number;
    card_type: 1 | 2;
    card_title: string;
    card_content: string;
    brand_id: number;
    line_id: string;
    mobile_number: string;
    created_at: string;
    updated_at: string;
    hash_id: string;
    message_text: string;
    image_urls: string[];
    card_idx: number | null;
    user_category_id: number | null;
    category_name: string | null;
    user_folder_id: number | null;
    folder_name: string | null;
  };
}
export interface queryNameCardType {
  data: nameCardType[];
}

interface deleteMessageType {
  data: {
    success: boolean;
    message: string;
  };
}

/**
 * 查詢會員數位名片資料
 * @param accessToken user access Token
 */
export const queryNameCards = async (
  accessToken: string
): Promise<Response<queryNameCardType>> => {
  try {
    const { data } = await axios.request<queryNameCardType>({
      url: `/api/member/nameCards`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 使用手機號碼或 line_id 查詢名片列表
 * @param accessToken user access Token
 * @param mobile_number user mobile_number
 * @param line_id user line_id
 * @param user_category_id user category id
 * @param user_folder_id user folder id
 * @param key_word search key word
 */
export const queryNameCardList = async (
  accessToken: string,
  mobile_number?: string,
  line_id?: string,
  user_category_id?: string,
  user_folder_id?: string,
  key_word?: string
): Promise<Response<queryNameCardType>> => {
  try {
    const params = new URLSearchParams();
    if (mobile_number) {
      params.append("mobile_number", mobile_number);
    } else if (line_id) {
      params.append("line_id", line_id);
    } else {
      throw new Error("Both mobile_number and line_id are missing.");
    }
    if (user_category_id) params.append("user_category_id", user_category_id);
    if (user_folder_id) params.append("user_folder_id", user_folder_id);
    if (key_word) params.append("key_word", key_word);

    console.log("params", params.toString());
    const { data } = await axios.request<queryNameCardType>({
      url: `/api/member/nameCards?${params.toString()}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 刪除會員數位名片
 * @param card_id card id
 * @param accessToken user access Token
 */
export const deleteNameCard = async (
  accessToken: string,
  card_id: number
): Promise<Response<deleteMessageType>> => {
  try {
    console.log(`/api/member/nameCards/${card_id}`);

    const { data } = await axios.request<deleteMessageType>({
      url: `/api/member/nameCards/${card_id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

interface addNameCardsType {
  line_id?: string;
  mobile_number?: string;
  card_type: 1 | 2;
  card_title: string;
  message_text: string;
  card_content: string;
}
interface addNameCardsResType {
  data: {
    success: boolean;
    message: string;
    data: {
      line_id?: string;
      mobile_number?: string;
      brand_id: number;
      updated_at: string;
      created_at: string;
      id: number;
      hash_id: string;
    };
  };
}
/**
 * 新增名片
 * @param accessToken user access Token
 * @param nameCardContent nameCard content json
 */
export const addNameCards = async (
  accessToken: string,
  nameCardContent: addNameCardsType
): Promise<Response<addNameCardsResType>> => {
  try {
    const { data } = await axios.request<addNameCardsResType>({
      url: "api/member/nameCards",
      method: "POST",
      data: {
        ...nameCardContent,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 修改名片
 * @param accessToken user access Token
 * @param card_id card id
 * @param nameCardContent nameCard content json
 */
export const updateNameCards = async (
  accessToken: string,
  card_id: number,
  nameCardContent: addNameCardsType
): Promise<Response<addNameCardsResType>> => {
  try {
    const { data } = await axios.request<addNameCardsResType>({
      url: `/api/member/nameCards/${card_id}`,
      method: "PATCH",
      data: {
        ...nameCardContent,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 查詢指定數位名片資料
 * @param accessToken user access Token
 * @param card_id card id
 *
 */
export const queryThisNameCard = async (
  accessToken: string,
  card_id: string
): Promise<Response<queryThisNameCardType>> => {
  try {
    const { data } = await axios.request<queryThisNameCardType>({
      url: `/api/member/nameCards/${card_id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

/**
 * 查詢指定hash_id的數位名片資料
 * @param accessToken user access Token
 * @param hash_id name card hash id
 *
 */
export const queryNameCardByHashId = async (
  accessToken: string,
  hash_id: string
): Promise<Response<queryNameCardType>> => {
  try {
    const { data } = await axios.request<queryNameCardType>({
      url: `/api/member/nameCards?hash_id=${hash_id}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

export interface uploadImgRspType {
  data: {
    success: boolean;
    message: string;
    data: {
      brand_id: number;
      image_url: string;
      update_at: string;
      create_at: string;
      id: number;
    };
  };
}
export const uploadImageToS3 = async (
  accessToken: string,
  file: FormData
): Promise<Response<uploadImgRspType>> => {
  try {
    const { data } = await axios.request<uploadImgRspType>({
      url: `api/member/nameCardImages`,
      method: "POST",
      data: file,

      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Access-Control-Allow-Origin": "*",
      },
    });

    return { data };
  } catch (error: any) {
    console.error("上傳失敗", error);
    console.log(file);
    return { error, data: undefined };
  }
};

type userCheckSuccessResponseType = {
  data: {
    success: boolean;
    message: string;
    user: userStateType;
  };
};

type userCheckErrorResponseType = {
  data: {
    success: false;
    message: string;
    mobile_number: string;
  };
};

export type UserCheckResponse =
  | userCheckSuccessResponseType
  | userCheckErrorResponseType;

export const memberCheck = async (
  accessToken: string,
  mobile_number: string,
  realname: string,
  email: string | undefined, // 24.12.03 SCH ★
  authCode: string
): Promise<Response<UserCheckResponse>> => {
  try {
    const { data } = await axios.request<UserCheckResponse>({
      url: `/api/member/user_check`,
      method: "POST",
      data: {
        mobile_number: mobile_number,
        realname: realname,
        email: email,
        auth_code: authCode,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return { data };
  } catch (error: any) {
    console.error("Error", error);
    return { error, data: undefined };
  }
};

// 24.10.31 SCH 查詢品牌部分資訊(一般會員的參數欄位)
export interface brandInfoType {
  data: {
    trial_name_cards: 0;
    trial_months: 0;
    trial_login_times: 0;
    use_name_card_count: 0;
    use_trial_due_at: 0;
    use_login_log_count: 0;
    login_log_count: 0;
    trial_name_card3: 0; // 25.01.02 SCH ★
  };
}

/**
 * 查詢品牌部分資訊(一般會員的參數欄位) // 24.10.31 SCH
 * @param accessToken user access Token
 *
 */
export const queryBrandInfo = async (
  accessToken: string
): Promise<Response<brandInfoType>> => {
  try {
    const { data } = await axios.request<brandInfoType>({
      url: `/api/member/get-brand-info`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

// 24.11.02 SCH ★ // 24.11.14 SCH ★
export interface userBonusType {
  order_id: number;
  uid: string | null;
  user_id: number;
  mobile_number: string | null;
  name: string | null;
  email: string | null;
  plan_name: string;
  status: string;
  payment_status: string;
  amount: number;
  commission: number;
  created_at: string;
  updated_at: string;
}
interface queryUserBonusType {
  original_commission: number;
  commission_discount: number;
  amount_commission: number;
  dates_range2: string;
  data: userBonusType[]; // 24.11.14 SCH ★
}

/**
 * 查詢會員推廣佣金資料 (API-M751 聯盟行銷推廣佣金)
 * @param accessToken user access Token // 24.11.04 SCH ★
 */
export const queryUserBonus = async (
  accessToken: string,
  y4m2?: string, // 佣金所屬年月 (一般情況使用此一欄位即可, 當沒給值時則抓全部資料)
  date_start?: string, // 銷售業績統計起算日 (option)
  date_end?: string // 銷售業績統計截止日 (option)
): Promise<Response<queryUserBonusType>> => {
  try {
    const { data } = await axios.request<queryUserBonusType>({
      url: `/api/member/affiliate/list-commissions-range`,
      method: "POST",
      data: {
        y4m2: y4m2,
        date_start: date_start,
        date_end: date_end,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

// 24.11.14 SCH ★
export interface planType {
  plan_id: 1 | 2 | 3 | number;
  name: string;
  description: string | null;
  type: number; // 24.11.14 SCH ★ 類型（1：終生版, 2：訂閱制）
  period_type: string; // 效期類型（Y：年, M：月）
  period_num: number; // 24.11.14 SCH ★ (年數 or 月數)
  original_price: number; // 原始價格
  discount_price: number | null; // 優惠價格 (null)
  enable: 0 | 1;
}
interface queryPlanType {
  data: planType[]; // 24.11.14 SCH ★
}

/**
 * 查詢會員推廣佣金資料 (API-M751 聯盟行銷推廣佣金)
 * @param accessToken user access Token // 24.11.04 SCH ★
 */
export const queryPlanData = async (
  accessToken: string
): Promise<Response<queryPlanType>> => {
  try {
    const { data } = await axios.request<queryPlanType>({
      url: `/api/member/get-plan-data`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};

type SendAuthCodeType = {
  success: boolean; // 24.12.01 SCH ★
  message: string;
  auth_code: string; // 24.12.01 SCH ★
  auth_lead: string; // 24.11.30 SCH ★
};

/**
 * 發送驗證碼
 * @param brand_id 品牌代號
 * @param mobile_number 手機號碼
 * @param type 類型
 * @param type
 */
export const sendAuthCode = async (
  accessToken: string,
  brand_id: number,
  mobile_number: string,
  type: "M" | "R"
): Promise<Response<SendAuthCodeType>> => {
  try {
    const { data } = await axios.request<SendAuthCodeType>({
      url: `/api/member/otp/send-auth-code`,
      method: "POST",
      data: { brand_id: brand_id, mobile_number: mobile_number, type: type },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error: error, data: undefined };
  }
};

/**
 * 驗證驗證碼
 * @param accessToken user access Token
 * @param brand_id 品牌代號
 * @param mobile_number 手機號碼
 * @param auth_code 驗證碼
 */
export const authenticationCode = async (
  accessToken: string,
  brand_id: number,
  mobile_number: string,
  auth_code: number
): Promise<Response<{ message: string }>> => {
  try {
    const { data } = await axios.request<{ message: string }>({
      url: `/api/member/otp/authentication`,
      method: "POST",
      data: {
        brand_id: brand_id,
        mobile_number: mobile_number,
        auth_code: auth_code,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return { data };
  } catch (error: any) {
    console.log(error);
    return { error, data: undefined };
  }
};
