import { Input, Row, Col, Button, Form } from "antd";
import classes from "./TextInput.module.sass";
import { ITextInputProps } from "./Type";

import {
  nameCardV1DataType,
  useAppStateStore,
} from "../../../../store/appState";

const TextInput = ({ id, elementIndex, pageIndex }: ITextInputProps) => {
  const { NAMECARD_DATA } = useAppStateStore();
  const { nameCardData, setNameCardV1Data } = NAMECARD_DATA;
  const { nameCardV1Data } = nameCardData;
  const index = nameCardV1Data?.findIndex((item) => item.id === id);

  const remove = (_index: number) => {
    if (nameCardV1Data) {
      const newFlexMsg: nameCardV1DataType[] = JSON.parse(
        JSON.stringify(nameCardV1Data)
      );
      newFlexMsg[index ?? 0].IntroText.splice(_index, 1);
      // 重新設定剩餘項目的 id
      newFlexMsg[index ?? 0].IntroText = newFlexMsg[index ?? 0].IntroText.map(
        (item, idx) => ({
          ...item,
          id: idx,
        })
      );
      setNameCardV1Data(newFlexMsg);
    }
  };
  return (
    <Form.Item
      // 使用唯一的 name
      className={classes.formItem}
      name={`describeText-${id}-${elementIndex}`}
      label="5.說明文字"
      // rules={[{ required: true, message: "請輸入說明文字" }]}
    >
      <Row justify="center" align="middle" className={classes.container}>
        <Col span={24}>
          <div className={classes.content}>
            <div className={classes.col}>
              <Input
                type="text"
                placeholder="非必填"
                id={`${elementIndex}`}
                onChange={(e) => {
                  if (nameCardV1Data) {
                    const _flexMessage: nameCardV1DataType[] = JSON.parse(
                      JSON.stringify(nameCardV1Data)
                    );
                    _flexMessage[index ?? 0].IntroText.forEach((text) => {
                      if (text._key === id && text.id === Number(e.target.id)) {
                        text.inputValue = e.target.value;
                      }
                    });
                    setNameCardV1Data(_flexMessage);
                  }
                }}
                value={
                  nameCardV1Data === null
                    ? undefined
                    : nameCardV1Data[pageIndex ?? 0].IntroText[elementIndex]
                        .inputValue
                }
              />
            </div>
            <div>
              <Button
                size="small"
                className={classes.button}
                onClick={() => remove(elementIndex)}
              >
                X
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Form.Item>
  );
};
export default TextInput;
