import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import classes from "../Footer.module.sass";
import { addNameCards, updateNameCards } from "../../../api";
import { INameCardType } from "../../../interface/nameCard";
import {
  useShareFlexMsg,
  IFlexMsgContent,
} from "../../../hooks/useShareFlexMsg";
import {
  useAppStateStore,
  nameCardV1DataType,
  nameCardV1SettingType,
} from "../../../store/appState";

const NameCardsListFooter = () => {
  const navigate = useNavigate();
  const { sendFlexMsgFromCurrentContent } = useShareFlexMsg();
  const { NAMECARD_DATA, USER } = useAppStateStore();
  const {
    nameCardData,
    initNameCardV1Data,
    initNameCardV1Setting,
    initCurrentCategory,
    initNameCardV1Form,
  } = NAMECARD_DATA;
  const { nameCardV1Data, nameCardV1Setting, currentCategory, nameCardV1Form } =
    nameCardData;
  const { accessToken } = USER.userState;

  const sendMsgContent: { _flexMsgContent: IFlexMsgContent; altText: string } =
    {
      _flexMsgContent: {
        flexMsgType: { flexMsgType: "V1.0FlexMsg" },
        currentContent: {
          flexMsg: nameCardV1Data!,
          flexMsgSetting: nameCardV1Setting,
        },
      },
      altText: nameCardV1Setting.altText,
    };

  /**
   * 發建立數位名片 api
   * @param _accessToken //會員專屬的 accessToken
   */
  const AddNameCard = async (_accessToken: string) => {
    const _cardContent = { nameCardV1Setting, nameCardV1Data };

    const getImgUrls = (content: nameCardV1DataType[] | null) => {
      const imageUrls: string[] = [];
      if (content) {
        content.forEach((message) => {
          message.imageUrl.forEach((image) => {
            if (image.imageUrl.includes("brand/name_cards/")) {
              imageUrls.push(image.imageUrl);
            }
          });
        });
      }
      return imageUrls;
    };

    const nameCardContent: INameCardType = {
      card_type: nameCardV1Data && nameCardV1Data.length > 1 ? 2 : 1,
      card_title: nameCardV1Setting.cardTitle,
      message_text: nameCardV1Setting.altText,
      card_content: JSON.stringify(_cardContent),
      image_urls: getImgUrls(nameCardV1Data),
      user_category_id: currentCategory.user_category_id,
      category_name: currentCategory.category_name,
      user_folder_id: currentCategory.user_folder_id,
      folder_name: currentCategory.folder_name,
    };
    try {
      if (_accessToken) {
        const { data: res } = await addNameCards(_accessToken, nameCardContent);
        if (res) {
          const { data } = res;
          const hasShareNameCardButton =
            nameCardContent.card_content.indexOf("分享名片") !== -1;
          const nameCardHash = data.data.hash_id;
          console.log("nameCardHash", nameCardHash);
          const cardID = data.data.id;

          console.log("hasShareNameCardButton", hasShareNameCardButton);
          if (hasShareNameCardButton) {
            const newNameCardContent: INameCardType = JSON.parse(
              JSON.stringify(nameCardContent)
            );
            const { card_content } = newNameCardContent;
            const newCardContent: {
              nameCardV1Setting: nameCardV1SettingType;
              nameCardV1Data: nameCardV1DataType[];
            } = JSON.parse(card_content);
            const result: nameCardV1DataType[] =
              newCardContent.nameCardV1Data.map((_flexMsg, index) => {
                return {
                  ..._flexMsg,
                  button: _flexMsg.button.map((_button) => {
                    if (
                      _button.type === "shareNameCardButton" &&
                      !_button.content.buttonUrl.includes(nameCardHash)
                    ) {
                      _button.content.buttonUrl += nameCardHash;
                      return _button;
                    } else {
                      return _button;
                    }
                  }),
                };
              });

            console.log("加上分享自己按鈕的 flex message :", result);
            const updateContent = {
              nameCardV1Setting: nameCardV1Setting,
              nameCardV1Data: result,
            };
            const updateNameCardData: INameCardType = {
              card_type: result && result.length > 1 ? 2 : 1,
              card_title: nameCardV1Setting.cardTitle || "電子名片標題",
              message_text: nameCardV1Setting.altText || "電子名片訊息",
              card_content: JSON.stringify(updateContent),
              image_urls: getImgUrls(result),
              user_category_id: currentCategory.user_category_id,
              category_name: currentCategory.category_name,
              user_folder_id: currentCategory.user_folder_id,
              folder_name: currentCategory.folder_name,
            };
            const { data: updateNameCardRes } = await updateNameCards(
              _accessToken,
              cardID,
              updateNameCardData
            );
            console.log(updateNameCardRes);
          }
        }
      }
    } catch (error) {
      console.log("建立新名片錯誤", error);
    }
  };

  const handleSubmit = async () => {
    try {
      const forms = Object.values(nameCardV1Form);
      console.log("forms", forms);
      const values = await Promise.all(
        forms.map((item) => item.validateFields())
      );
      // 驗證通過才執行提交邏輯
      console.log("所有表單驗證通過，表單數據：", values);
      accessToken && AddNameCard(accessToken);
      navigate(`/`);
      initNameCardV1Form();
      // 執行提交邏輯
    } catch (errorInfo) {
      // 驗證失敗，errorInfo 包含錯誤信息
      console.log("表單驗證失敗:", errorInfo);
      message.error("請依照欄位提示修正以符合所需格式");
    }
  };

  /**
   * 發送名片
   */
  const sendNameCard = async () => {
    try {
      if (!nameCardV1Data) return;
      const forms = Object.values(nameCardV1Form);
      const values = await Promise.all(
        forms.map((item) => item.validateFields())
      );
      console.log("所有表單驗證通過，表單數據：", values);

      sendFlexMsgFromCurrentContent(
        sendMsgContent._flexMsgContent,
        sendMsgContent.altText
      );
    } catch (error) {
      console.log("發送名片錯誤", error);
      message.error("發送名片錯誤");
    }
  };

  /**
   * 取消
   */
  const handleCancel = () => {
    navigate(`/`);
    initNameCardV1Data();
    initNameCardV1Setting();
    initCurrentCategory();
    initNameCardV1Form();
  };
  return (
    <div className={`${classes.buttonItem}  ${classes.navBar}`}>
      <div className={`${classes.buttonItem} ${classes.cancelButton}`}>
        <Button
          type="primary"
          className={`${classes.button} ${classes.buttonGreyBg}`}
          onClick={handleCancel}
        >
          <div className={classes.buttonWord}>取消</div>
        </Button>
      </div>
      <div className={classes.buttonItem}>
        <Button
          type="primary"
          className={classes.button}
          onClick={handleSubmit}
        >
          <div className={classes.buttonWord}>儲存</div>
        </Button>
      </div>
      <div className={classes.buttonItem}>
        <Button type="primary" className={classes.send} onClick={sendNameCard}>
          <div className={classes.buttonWord}>發送</div>
        </Button>
      </div>
    </div>
  );
};

export default NameCardsListFooter;
