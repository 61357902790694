import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Input,
  Form,
  Select,
  Modal,
  Col,
  Row,
  UploadProps,
  message,
  Upload,
} from "antd";
import { CFlexBubble, LiffMessage } from "../../../flexMessageType";
import { FlexComponent, FlexBox, FlexImage } from "@line/bot-sdk/dist/types";
import { fabric } from "fabric";
import { queryThisNameCard, uploadImageToS3 } from "../../api";
import classes from "./CreateFullImgModeNameCard.module.sass";
import { UploadRequestOption } from ".../../../../node_modules/rc-upload/lib/interface";
import { UploadOutlined } from "@ant-design/icons";

import { useAppStateStore } from "../../store/appState";
import {
  fullImgModeNameCardAltUrlConfig,
  fullImgModeNameCardButtonType,
  fullImgModeNameCardButtonLabelConfig,
  fullImgModeNameCardConfig,
} from "../../config/fullImgModeNameCardConfig";

import CategorySelector from "../CreateNameCard/CategorySelector";
import isValidHttpUrl from "../../utils/isValidHttpUrl";

// 縮放的倍數
const scaleFactor = 1.3;
// flex  container rect 物件寬度
// line message 寬度都是固定的，這邊的 390 是根據 line flex message simulator * 1.3 倍
const flexContainerWidth = 390;

// 每一頁 flex message 的基本資料
const newPage = (_pageNum: number) => {
  const result: CFlexBubble = {
    type: "bubble",
    body: {
      type: "box",
      layout: "vertical",
      contents: [], // 在這裡添加新頁面的內容
      paddingAll: "0px",
    },
  };
  return result;
};

interface INameCardFullImgModeContentProps {
  isUpdate: boolean;
  cardid?: number;
}

const CreateFullImgModeNameCard = ({
  isUpdate,
  cardid,
}: INameCardFullImgModeContentProps) => {
  const { PAGEMODE, APP, USER, NAMECARD_DATA, BODYBLOCKREF, BRAND } =
    useAppStateStore();
  const { currentConfig } = APP;
  const { setCurrentPageModeState } = PAGEMODE;
  const { accessToken, role, is_normal, eagle } = USER.userState; // 24.10.30 SCH
  const { brandState } = BRAND; // 24.11.21 SCH ★
  const {
    nameCardData,
    setNameCardV3Data,
    setNameCardV3Setting,
    setCurrentCategory,
    setNameCardV3Form,
  } = NAMECARD_DATA;
  const { nameCardV3Data, nameCardV3Setting } = nameCardData;
  const { bodyBlockRef } = BODYBLOCKREF;
  const [isPermissionMsgModalOpen, setIsPermissionMsgModalOpen] =
    useState<boolean>(false); // 24.10.30 SCH

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [imgUrl, setImgUrl] = useState<string | null>(null);
  const [btnUrl, setBtnUrl] = useState<string | null>(null);
  const [cardHashID, setCardHashID] = useState<string | undefined>(undefined);
  const [isShareNameCardButton, setIsShareNameCardButton] =
    useState<boolean>(false);

  const canvasRef = useRef(null);
  const infoBlockRef = useRef<HTMLDivElement>(null);
  const previewBlockRef = useRef<HTMLDivElement>(null);
  const pageEditBlockRef = useRef<HTMLDivElement>(null);
  const currentBlockRef = useRef<
    "infoBlock" | "previewBlock" | "pageEditBlock"
  >("infoBlock");
  // const msgContentRef = useRef(nameCardV3Data);
  const msgContentRef = useRef<CFlexBubble[]>([]);

  const maxHeightRef = useRef<number>(585);

  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const [isValidateImageUrl, setIsValidateImageUrl] = useState<boolean>(true);
  const [buttonIndex, setButtonIndex] = useState<number | null>(null);
  const [isOpenDeletePageModal, setIsOpenDeletePageModal] =
    useState<boolean>(false);
  const [isOpenDeleteButtonModal, setIsOpenDeleteButtonModal] =
    useState<boolean>(false);
  const { Option } = Select;
  const [willAddButtonType, setWillAddButtonType] = useState<
    "urlButton" | "phoneButton" | "shareNameCardButton"
  >("urlButton");
  const [maxHeightInfo, setMaxHeightInfo] = useState<{
    done: boolean;
    isUpdateMaxHeightInfo: boolean;
    maxHeight: number;
  }>({ done: false, isUpdateMaxHeightInfo: false, maxHeight: 0 });
  const [shouldRender, setShouldRender] = useState<{
    isFlexContainerShouldRender: boolean;
    isFlexButtonShouldRender: boolean;
  }>({
    isFlexContainerShouldRender: true,
    isFlexButtonShouldRender: true,
  });
  const [step1, setSetep1] = useState<boolean>(true);
  const [isUpdateCoverImgUrl, setIsUpdateCoverImgUrl] = useState<{
    isUpdateCoverImgUrl: boolean;
    flexContainerIndex?: number;
  }>({ isUpdateCoverImgUrl: false, flexContainerIndex: undefined });

  const [form] = Form.useForm();

  const showDeletePageModal = () => {
    setIsOpenDeletePageModal(true);
  };

  const handleDeletePageModalCancel = () => {
    setIsOpenDeletePageModal(false);
  };

  const showDeleteButtonModal = () => {
    setIsOpenDeleteButtonModal(true);
  };

  const handleDeleteButtonModalCancel = () => {
    setIsOpenDeleteButtonModal(false);
  };

  /**
   * 移除 "tel:+886" 前綴，只用於顯示
   * @param phoneNumber 電話號碼。
   */
  const formatPhoneNumberForDisplay = (phoneNumber: string): string => {
    // 移除 "tel:+886" 前綴，只用於顯示
    return phoneNumber.replace(/^tel:\+886/, "");
  };

  /**
   * 處理電話號碼，如果是用於顯示，則移除 "tel:+886" 前綴，如果是用於傳送，則添加 "tel:+886" 前綴
   * @param phoneNumber 電話號碼。
   * @param isForDisplay 是否用於顯示。
   */
  const handlePhoneNumber = (
    phoneNumber: string,
    isForDisplay: boolean
  ): string => {
    if (isForDisplay) {
      return formatPhoneNumberForDisplay(phoneNumber);
    } else {
      return phoneNumber.startsWith("tel:+886")
        ? phoneNumber
        : `tel:+886${phoneNumber}`;
    }
  };

  /**
   * 移除電話號碼的錯誤前綴
   * @param url 電話號碼。
   * @param isPhoneButton 是否為電話按鈕。
   */
  const cleanIncorrectPhonePrefix = (
    url: string,
    isPhoneButton: boolean
  ): string => {
    if (!isPhoneButton && url.startsWith("tel:+886")) {
      return url.replace("tel:+886", "");
    }
    return url;
  };

  /**
   * 載入並清除錯誤的電話號碼前綴
   * @param data 名片資料。
   * @returns 清理後的數據和是否有修改的標誌
   */
  const loadAndCleanData = (
    data: CFlexBubble[]
  ): { cleanedData: CFlexBubble[]; hasModifications: boolean } => {
    let hasModifications = false;

    const cleanedData = data.map((bubble) => {
      if (bubble.body && bubble.body.contents) {
        bubble.body.contents = bubble.body.contents.map((content) => {
          if (
            content.type === "box" &&
            content.action &&
            content.action.type === "uri"
          ) {
            const isPhone = isPhoneButton(content) || false;
            const cleanedUri = cleanIncorrectPhonePrefix(
              content.action.uri,
              isPhone
            );

            if (cleanedUri !== content.action.uri) {
              hasModifications = true;
              content.action.uri = cleanedUri;
            }
          }
          return content;
        });
      }
      return bubble;
    });

    return { cleanedData, hasModifications };
  };

  /**
   * 根據目前最高的高度渲染底圖到flex container rect 物件
   * @param imageUrl 背景圖片的 URL。
   * @param flexContainer 表示 flex 容器的 fabric.Rect。
   * @param canvas 進行渲染的 fabric.Canvas。
   * @param maxHeight 目前的最大高度。
   */
  const renderCoverImg = (
    imageUrl: string,
    flexContainer: fabric.Rect,
    canvas: fabric.Canvas,
    maxHeight: number
  ) => {
    fabric.Image.fromURL(
      imageUrl,
      (image) => {
        const flexContainerWidth = flexContainer.width;

        if (!flexContainerWidth) return;

        image.scaleToWidth(flexContainerWidth);

        // 在 patternSourceCanvas 上添加已缩放的图像
        const resizedImageDataURL = image.toDataURL({
          format: "jpeg",
          enableRetinaScaling: false,
          quality: 0.5,
        });

        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = resizedImageDataURL;

        img.onload = () => {
          // 创建一个 canvas 元素
          const tempCanvas = document.createElement("canvas");
          const context = tempCanvas.getContext("2d");

          // 设置 canvas 的宽度和高度
          tempCanvas.width = flexContainerWidth; // 设置宽度
          tempCanvas.height = maxHeight; // 设置宽度

          if (context) {
            // 在 canvas 上繪製內容，這裡繪製一個長寬都和flex container 一樣的長寬的矩形
            context.fillStyle = "white"; // 填滿白色
            context.fillRect(0, 0, tempCanvas.width, tempCanvas.height); // 填滿白色的矩形覆盖整個 canvas
            context.drawImage(img, 0, 0); // 繪製結合 resize image 和 已經填滿白色flex container 一樣的長寬的矩形到 canvas
          }

          // 使用 toDataURL 方法將 canvas 影像內容轉換為 base64
          const imageURL = tempCanvas.toDataURL("image/png");

          //將最終要顯示在flex
          const displayImage = new Image();
          displayImage.src = imageURL;
          displayImage.onload = () => {
            const pattern = new fabric.Pattern({
              source: displayImage,
              repeat: "no-repeat",
              offsetX: 0,
              offsetY: 0,
            });
            // alert(maxHeight);
            flexContainer.set({
              width: flexContainerWidth,
              height: maxHeight,
              fill: pattern,
            });
            canvas.requestRenderAll();
          };
        };
      },
      { crossOrigin: "anonymous" }
    );
  };

  const QueryThisNameCard = async (_card_id: string) => {
    try {
      const oldS3Basebuel =
        "https://ipflow-dev-s3.s3-ap-northeast-1.amazonaws.com/";
      if (accessToken) {
        const { data: res } = await queryThisNameCard(accessToken, _card_id);
        if (res) {
          const { data } = res;
          setCardHashID(data.hash_id);
          setNameCardV3Setting({
            cardTitle: data.card_title,
            altText: data.message_text,
          });
          setCurrentCategory({
            user_category_id: data.user_category_id,
            category_name: data.category_name,
            user_folder_id: data.user_folder_id,
            folder_name: data.folder_name,
          });

          // 取代最一開始舊版的 s3 baseurl 正規化
          if (
            !data.card_content.includes(oldS3Basebuel) &&
            data.card_content.indexOf(currentConfig.REACT_APP_IMAGEPATH) !== -1
          ) {
            const willReplace = new RegExp(
              `${currentConfig.REACT_APP_IMAGEPATH}`,
              "g"
            );
            data.card_content = data.card_content.replace(
              willReplace,
              currentConfig.REACT_APP_IMAGEBASEURL +
                currentConfig.REACT_APP_IMAGEPATH
            );
          }

          if (data.card_content) {
            const coverData: CFlexBubble[] = JSON.parse(data.card_content);
            let hasDataIssue = false;
            coverData.forEach((item) => {
              if (item.body && item.body.contents) {
                item.body.contents.forEach((content) => {
                  // 移除所有按鈕的 altUri
                  if (
                    content.type === "box" &&
                    content.action?.type === "uri" &&
                    content.action.altUri
                  ) {
                    delete content.action.altUri;
                    hasDataIssue = true; // 標記數據有問題
                  }

                  if (
                    content.type === "box" &&
                    content.action &&
                    content.action.type === "uri"
                  ) {
                    content.contents = content.contents || [];

                    // 檢查是否已經存在輔助判斷的子物件
                    const hasHelperContent = content.contents.some(
                      (c) =>
                        c.type === "text" &&
                        (c.text ===
                          fullImgModeNameCardButtonType.shareNameCardButton
                            .text ||
                          c.text ===
                            fullImgModeNameCardButtonType.callphoneButton
                              .text ||
                          c.text ===
                            fullImgModeNameCardButtonType.urlButton.text)
                    );

                    // 如果不存在輔助判斷的子物件，則添加一個
                    if (!hasHelperContent) {
                      let helperContent;
                      hasDataIssue = true; // 標記數據有問題
                      if (content.action.label === "shareNameCardButton") {
                        content.action.label =
                          fullImgModeNameCardButtonLabelConfig.shareNameCardButton;
                        helperContent =
                          fullImgModeNameCardButtonType.shareNameCardButton;
                      } else if (
                        content.action.label === "buttonURI" &&
                        content.action.uri.indexOf("tel:+") !== -1
                      ) {
                        content.action.label =
                          fullImgModeNameCardButtonLabelConfig.phoneButton;
                        helperContent =
                          fullImgModeNameCardButtonType.callphoneButton;
                      } else {
                        helperContent = fullImgModeNameCardButtonType.urlButton;
                      }
                      content.contents.push(helperContent);
                    }
                  }
                });
              }
            });
            data.card_content = JSON.stringify(coverData);
            if (hasDataIssue) {
              // 如果檢測到數據問題，顯示提醒
              message.warning(
                "檢測到名片數據格式有問題，建議您儲存以更新資料。",
                5
              );
            }
          }

          if (data.card_content.indexOf("carousel") !== -1) {
            // 清理並設置數據
            const { cleanedData, hasModifications } = loadAndCleanData(
              JSON.parse(data.card_content)
            );
            hasModifications &&
              message.warning(
                "檢測到名片數據按鈕格式含有錯誤url前綴，請您儲存以更新資料。",
                5
              );
            console.log(
              "更新後的名片數據:",
              JSON.parse(data.card_content).contents
            );
            setNameCardV3Data(cleanedData);
          } else {
            try {
              const parsedFlexBubbles: CFlexBubble[] = JSON.parse(
                data.card_content
              );
              const updatedFlexBubbles: CFlexBubble[] = parsedFlexBubbles.map(
                (flexBubble) => {
                  if (
                    flexBubble.body?.contents &&
                    flexBubble.body.contents.length > 0
                  ) {
                    const firstImage = flexBubble.body.contents[0];
                    if (firstImage.type === "image") {
                      const isImageUrlNeedsUpdate = firstImage.url.includes(
                        currentConfig.REACT_APP_IMAGEPATH
                      );
                      if (isImageUrlNeedsUpdate) {
                        const updateAspectRatio = async () => {
                          const aspectRatioResult = await calculateAspectRatio(
                            firstImage.url
                          );
                          const { aspectRatioHeight, aspectRatioWidth } =
                            aspectRatioResult;
                          firstImage.aspectRatio = `${aspectRatioWidth}:${aspectRatioHeight}`;
                          console.log(firstImage.url);
                        };

                        updateAspectRatio();
                      }
                    }
                  }
                  return flexBubble;
                }
              );
              console.log("更新後的名片數據:", updatedFlexBubbles);
              // 清理並設置數據
              const { cleanedData, hasModifications } =
                loadAndCleanData(updatedFlexBubbles);
              console.log("清理後的名片數據:", cleanedData);
              hasModifications &&
                message.warning(
                  "檢測到名片數據按鈕格式含有錯誤url前綴，請您儲存以更新資料。",
                  5
                );
              setNameCardV3Data(updatedFlexBubbles);
            } catch (error) {
              console.error("Error", error);
            }
          }
        }
      }
    } catch (error) {
      console.log("error", error);
      console.log("flex message 格式不符合 跳轉回首頁");
    }
  };

  useEffect(() => {
    if (isUpdate) {
      QueryThisNameCard(`${cardid}`);
    }
  }, [isUpdate]);
  // 初始化 canvas
  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current);
    canvas.setBackgroundColor("#849ebf", () => {
      canvas.requestRenderAll();
    });

    if (canvas) {
      setCanvas(canvas);

      // 註冊方法

      //註冊方法 - 第一次選取物件時
      canvas.on("selection:created", (selectedItem) => {
        if (
          selectedItem.selected &&
          selectedItem.selected[0].name?.indexOf("flexContainer") !== -1
        ) {
          const selectedObj = selectedItem.selected;
          const selectedObjName = selectedObj[0].name;
          const thisFlexMsgPage = selectedObjName?.split("flexContainer-")[1];
          const isFlexContainerRect = selectedObjName?.indexOf("_") === -1;
          console.log("selectedObjName", selectedObjName);
          isFlexContainerRect && canvas.discardActiveObject();
          thisFlexMsgPage !== undefined &&
            setCurrentPage(parseInt(thisFlexMsgPage));

          // 顯示按鈕資訊 和 限制 flexContainer rect 不能移動
          if (
            selectedObjName &&
            selectedObjName.indexOf("flexButton") !== -1 &&
            thisFlexMsgPage !== undefined
          ) {
            const buttonIndex: number = parseInt(
              selectedObjName.split("_")[1].split("flexButton")[1]
            );
            console.log(buttonIndex);
            setButtonIndex(buttonIndex);
            if (
              msgContentRef.current[parseInt(thisFlexMsgPage)].body?.contents?.[
                buttonIndex
              ]?.type === "box"
            ) {
              const button =
                msgContentRef.current[parseInt(thisFlexMsgPage)].body?.contents[
                  buttonIndex
                ];
              if (button?.type === "box" && button.action?.type === "uri") {
                // setBtnUrl(button.action.uri);
                setBtnUrl(formatPhoneNumberForDisplay(button.action.uri));
                setIsShareNameCardButton(
                  button.action.label ===
                    fullImgModeNameCardButtonLabelConfig.shareNameCardButton &&
                    button.contents &&
                    button.contents.length > 0 &&
                    button.contents[0].type === "text" &&
                    button.contents[0].text ===
                      fullImgModeNameCardButtonType.shareNameCardButton.text
                );
              }
            }
          }
        }
      });

      //註冊方法 - 監聽變換選取物件時
      canvas.on("selection:updated", (selectedItem) => {
        if (selectedItem.selected) {
          const selectedObj = selectedItem.selected;
          const selectedObjName = selectedObj[0].name;
          const thisFlexMsgPage = selectedObjName?.split("flexContainer-")[1];
          const isFlexContainerRect = selectedObjName?.indexOf("_") === -1;
          console.log("selectedObjName", selectedObjName);
          isFlexContainerRect && canvas.discardActiveObject();
          thisFlexMsgPage !== undefined &&
            setCurrentPage(parseInt(thisFlexMsgPage));
          if (selectedObjName && selectedObjName.indexOf("flexButton") !== -1) {
            const buttonIndex: number = parseInt(
              selectedObjName.split("_")[1].split("flexButton")[1]
            );
            setButtonIndex(buttonIndex);
            // 顯示按鈕資訊
            if (
              selectedObjName &&
              selectedObjName.indexOf("flexButton") !== -1 &&
              thisFlexMsgPage !== undefined
            ) {
              const buttonIndex: number = parseInt(
                selectedObjName.split("_")[1].split("flexButton")[1]
              );
              if (
                msgContentRef.current[parseInt(thisFlexMsgPage)].body
                  ?.contents?.[buttonIndex]?.type === "box"
              ) {
                const button =
                  msgContentRef.current[parseInt(thisFlexMsgPage)].body
                    ?.contents[buttonIndex];
                if (button?.type === "box" && button.action?.type === "uri") {
                  setBtnUrl(button.action.uri);
                  setIsShareNameCardButton(
                    button.action.label ===
                      fullImgModeNameCardButtonLabelConfig.shareNameCardButton &&
                      button.contents &&
                      button.contents.length > 0 &&
                      button.contents[0].type === "text" &&
                      button.contents[0].text ===
                        fullImgModeNameCardButtonType.shareNameCardButton.text
                  );
                }
              }
            }
          }
        }
      });

      // 監聽當 canvas上物件被修改(移動、縮小、放大)
      canvas.on("object:modified", (scalingObj) => {
        const modifiedObjName = scalingObj.target?.name;
        const scaleWidthValue = scalingObj.target?.getScaledWidth();
        const scaleHeightValue = scalingObj.target?.getScaledHeight();
        const newTop = scalingObj.target?.top;
        const newleft = scalingObj.target?.left;
        const buttonWidth = scalingObj.target?.width;
        const buttonHeight = scalingObj.target?.height;
        const buttonScaleX = scalingObj.target?.scaleX;
        const buttonScaleY = scalingObj.target?.scaleY;

        if (
          modifiedObjName === undefined ||
          buttonScaleX === undefined ||
          buttonScaleY === undefined ||
          newTop === undefined ||
          newleft === undefined ||
          buttonWidth === undefined ||
          buttonHeight === undefined ||
          scaleWidthValue === undefined ||
          scaleHeightValue === undefined
        )
          return;

        if (modifiedObjName?.indexOf("_") === -1) return; // 代表如果是  flex contianer 物件 就 return
        const modifiedFlexMsgPage: number = parseInt(
          modifiedObjName.split("_")[0].split("-")[1]
        );
        const buttonIndex: number = parseInt(
          modifiedObjName.split("_")[1].split("flexButton")[1]
        );

        // 拷貝目前 flex message 訊息
        const newContent: CFlexBubble[] = JSON.parse(
          JSON.stringify(msgContentRef.current)
        );

        if (
          newContent[modifiedFlexMsgPage].body?.contents?.[buttonIndex]
            ?.type === "box"
        ) {
          const button =
            newContent[modifiedFlexMsgPage].body?.contents[buttonIndex];
          if (button !== undefined && button.type === "box") {
            const resultWidth = () => {
              if (scaleWidthValue > flexContainerWidth) {
                return Math.round(flexContainerWidth / scaleFactor);
              } else {
                return Math.round((buttonWidth * buttonScaleX) / scaleFactor);
              }
            };

            const resultHeight = () => {
              if (scaleHeightValue > maxHeightRef.current) {
                return Math.round(maxHeightRef.current / scaleFactor);
              } else {
                return Math.round((buttonHeight * buttonScaleY) / scaleFactor);
              }
            };

            const resultLeft =
              resultWidth() === Math.round(flexContainerWidth / scaleFactor)
                ? 0
                : (newleft -
                    (modifiedFlexMsgPage * 390 + modifiedFlexMsgPage * 25)) /
                  scaleFactor;

            const resultTop =
              resultHeight() === Math.round(maxHeightRef.current / scaleFactor)
                ? 0
                : Math.round((newTop - 25) / scaleFactor);
            button.offsetTop = `${resultTop}px`; // 扣掉顯示用往下移動25px
            button.offsetStart = `${resultLeft}px`; // 扣掉顯示用往下移動25px

            if (buttonScaleX !== 1 || buttonScaleY !== 1) {
              button.width = `${resultWidth()}px`;
              button.height = `${resultHeight()}px`;
            }
          }
          setNameCardV3Data(newContent);
          console.log(scalingObj.target?.name, "修改完成");
        }
      });
    }
    setCurrentPageModeState(
      isUpdate ? "updateNameCardFullImgMode" : "createNameCardFullImgMode"
    );
  }, []);

  /**
   * 根據底圖計算目前最高的高度
   * @param flexContainerWidth  flex container rect 物件的寬度
   */
  const calculateMaxHeight = async (flexContainerWidth: number) => {
    let currentMaxHeight = 0;
    await Promise.all(
      nameCardV3Data.map(async (flexBubble, index) => {
        if (flexBubble.body && flexBubble.body.contents) {
          await Promise.all(
            flexBubble.body.contents.map(async (element) => {
              if (element.type === "image" && element.url) {
                const imageUrl = element.url + "?time=" + new Date().valueOf();

                // 使用 Promise 包装 fabric.Image.fromURL
                return new Promise((resolve) => {
                  fabric.Image.fromURL(
                    imageUrl,
                    (image) => {
                      image.scaleToWidth(flexContainerWidth);
                      if (image.getScaledHeight() > currentMaxHeight) {
                        currentMaxHeight = image.getScaledHeight();
                      }
                      resolve(true);
                    },
                    { crossOrigin: "anonymous" }
                  );
                });
              }
            })
          );
        }
      })
    );
    return currentMaxHeight;
  };

  useEffect(() => {
    setImgUrl(currentPageBackgroundImageUrl());
    previewBlockRef.current &&
      scrollToPositionLeftRight(previewBlockRef.current, currentPage);
  }, [currentPage]);

  useEffect(() => {
    console.log(maxHeightInfo);
    console.log("shouldRender", shouldRender);
    console.log("isUpdateCoverImgUrl", isUpdateCoverImgUrl);

    const { done, isUpdateMaxHeightInfo, maxHeight } = maxHeightInfo;
    const { isFlexContainerShouldRender, isFlexButtonShouldRender } =
      shouldRender;

    if (!canvas) return;

    // step1 是第一次渲染要把 name card data 內容生成到 canvas 上
    if (maxHeight !== 0 && done && isFlexContainerShouldRender && step1) {
      setSetep1(false); // 在這裡將 step1 設置為 false
    }

    if (maxHeight !== 0 && done) {
      if (step1 && isFlexContainerShouldRender) {
        nameCardV3Data.forEach((flexBubble, index) => {
          const flexContainer = new fabric.Rect({
            name: `flexContainer-${index}`,
            width: flexContainerWidth,
            height: maxHeight,
            fill: "#ffffff",
            strokeWidth: 2,
            top: 25,
            left: index * 25 + index * 390,
            rx: 10,
            ry: 10,
          });
          canvas.add(flexContainer);
          canvas.renderAll();
          if (flexBubble.body && flexBubble.body.contents) {
            flexBubble.body.contents.forEach((element, elementIndex) => {
              if (element.type === "image" && element.url) {
                const imageUrl = element.url + "?time=" + new Date().valueOf();
                // 處理底圖的 resize 並 轉成base64，利用 pattern  fill 到 flexContainer (這是呈現底圖的rect)
                renderCoverImg(imageUrl, flexContainer, canvas, maxHeight);
              }
            });
          }
        });
      } else if (isFlexContainerShouldRender) {
        // 這邊代表新增頁面
        // alert("isFlexContainerShouldRender");
        nameCardV3Data.forEach((flexBubble, index) => {
          const currentCanvasObjs = canvas.getObjects();
          const thisFlexContainer = currentCanvasObjs.find(
            (obj) => obj.name === `flexContainer-${index}`
          );

          //代表已經有生成這個 flexContainer rect 在 canvas 上
          if (thisFlexContainer) return;

          const flexContainer = new fabric.Rect({
            name: `flexContainer-${index}`,
            width: flexContainerWidth,
            height: maxHeight,
            fill: "#ffffff",
            strokeWidth: 2,
            top: 25,
            left: index * 25 + index * 390,
            rx: 10,
            ry: 10,
          });
          canvas.add(flexContainer);
          canvas.renderAll();
          if (flexBubble.body && flexBubble.body.contents) {
            flexBubble.body.contents.forEach((element, elementIndex) => {
              if (element.type === "image" && element.url) {
                const imageUrl = element.url + "?time=" + new Date().valueOf();
                // 處理底圖的 resize 並 轉成base64，利用 pattern  fill 到 flexContainer (這是呈現底圖的rect)
                renderCoverImg(imageUrl, flexContainer, canvas, maxHeight);
              }
            });
          }
        });
        if (isUpdateMaxHeightInfo) {
          // alert("更新 flexContainer ");
          const currentAllFlexContainer = canvas
            .getObjects()
            .filter((item) => item.name && item.name.indexOf("_") === -1);
          currentAllFlexContainer.forEach((item, index) => {
            console.log(item.name);
            item.set({
              height: maxHeight,
            });
            item.setCoords();

            nameCardV3Data[index].body?.contents.forEach(
              (flexComponent, flexComponentIndex) => {
                if (flexComponent.type === "image" && flexComponent.url) {
                  const imageUrl =
                    flexComponent.url + "?time=" + new Date().valueOf();
                  // 處理底圖的 resize 並 轉成base64，利用 pattern  fill 到 flexContainer (這是呈現底圖的rect)
                  renderCoverImg(imageUrl, item, canvas, maxHeight);
                }
              }
            );
          });
        }
      } else if (
        isUpdateCoverImgUrl.isUpdateCoverImgUrl &&
        isUpdateCoverImgUrl.flexContainerIndex !== undefined
      ) {
        // alert("");

        if (isUpdateMaxHeightInfo) {
          // alert("1");
          const currentAllFlexContainer = canvas
            .getObjects()
            .filter((item) => item.name && item.name.indexOf("_") === -1);
          currentAllFlexContainer.forEach((item, index) => {
            console.log(item.name);
            item.set({
              height: maxHeight,
            });
            item.setCoords();

            nameCardV3Data[index].body?.contents.forEach(
              (flexComponent, flexComponentIndex) => {
                if (flexComponent.type === "image" && flexComponent.url) {
                  const imageUrl =
                    flexComponent.url + "?time=" + new Date().valueOf();
                  // 處理底圖的 resize 並 轉成base64，利用 pattern  fill 到 flexContainer (這是呈現底圖的rect)
                  renderCoverImg(imageUrl, item, canvas, maxHeight);
                }
              }
            );
          });
          console.log(isUpdateCoverImgUrl.flexContainerIndex);
          const thisFlexContainerAllObjs = canvas
            .getObjects()
            .filter(
              (item) =>
                item.name &&
                item.name.indexOf(
                  `flexContainer-${isUpdateCoverImgUrl.flexContainerIndex}`
                ) !== -1
            );
          thisFlexContainerAllObjs.forEach((item, index) => {
            if (item.name && item.name.indexOf("_") === -1) {
              item.set({
                name: `flexContainer-${isUpdateCoverImgUrl.flexContainerIndex}`,
              });
            } else {
              item.set({
                name: `flexContainer-${isUpdateCoverImgUrl.flexContainerIndex}_flexButton${index}`,
              });
            }
          });
        } else {
          console.log(isUpdateCoverImgUrl.flexContainerIndex);
          const updateFlexContainer = canvas
            .getObjects()
            .find(
              (item) =>
                item.name &&
                item.name ===
                  `flexContainer-${isUpdateCoverImgUrl.flexContainerIndex}`
            );
          const imgurl =
            (
              nameCardV3Data[isUpdateCoverImgUrl.flexContainerIndex].body
                ?.contents[0] as FlexImage
            ).url +
            "?time=" +
            new Date().valueOf();
          if (updateFlexContainer)
            renderCoverImg(imgurl, updateFlexContainer, canvas, maxHeight);
          const thisFlexContainerAllObjs = canvas
            .getObjects()
            .filter(
              (obj) =>
                obj.name &&
                obj.name.indexOf(
                  `flexContainer-${isUpdateCoverImgUrl.flexContainerIndex}`
                ) !== -1
            );
          console.log(thisFlexContainerAllObjs);
          thisFlexContainerAllObjs.forEach(
            (_thisFlexContainerAllObj, _thisFlexContainerAllObjIndex) => {
              if (
                _thisFlexContainerAllObj.name &&
                _thisFlexContainerAllObj.name.indexOf("_") === -1
              ) {
                _thisFlexContainerAllObj.set({
                  name: `flexContainer-${isUpdateCoverImgUrl.flexContainerIndex}`,
                });
              } else {
                _thisFlexContainerAllObj.set({
                  name: `flexContainer-${isUpdateCoverImgUrl.flexContainerIndex}_flexButton${_thisFlexContainerAllObjIndex}`,
                });
              }
            }
          );
        }
      }
    }

    //  綠色區塊按鈕的渲染
    if (step1) {
      if ((isFlexButtonShouldRender || step1) && done) {
        nameCardV3Data.forEach((flexBubble, index) => {
          console.log(canvas.getObjects());
          const flexContainer = canvas
            .getObjects()
            .find((item) => item.name === `flexContainer-${index}`);
          console.log(flexContainer);
          if (!flexContainer) return;
          if (flexBubble.body && flexBubble.body.contents) {
            flexBubble.body.contents.forEach((element, elementIndex) => {
              if (element.type === "box" && element.action?.type === "uri") {
                const width = () => {
                  return (
                    parseInt(element.width?.split("px")[0] ?? "150") *
                    scaleFactor
                  );
                };

                const height = () => {
                  return parseInt(element.height?.split("px")[0] ?? "50") >
                    maxHeightRef.current
                    ? maxHeightRef.current
                    : parseInt(element.height?.split("px")[0] ?? "50") *
                        scaleFactor;
                };
                const top = Math.round(
                  parseInt(element.offsetTop?.split("px")[0] ?? "25") *
                    scaleFactor +
                    25
                ); // 25為顯示時往下降的高度

                const left = Math.round(
                  parseInt(element.offsetStart?.split("px")[0] ?? "0") *
                    scaleFactor +
                    (flexContainer.left ?? 0)
                );

                const flexButton = new fabric.Rect({
                  width: width(),
                  height: height(),
                  top: top,
                  left: left,
                  fill: "#00FF0080",
                  selectable: true,
                  name: `flexContainer-${index}_flexButton${elementIndex}`,
                });

                // 這邊是註冊移動事件 ，限制 flexButton rect 不能移動出去 該 flexContainer 的範圍
                flexButton.on("moving", function (e) {
                  const obj = e.transform?.target;
                  if (
                    !obj ||
                    !obj.width ||
                    !obj.height ||
                    !flexContainer.height ||
                    !obj.left ||
                    !obj.top
                  )
                    return;

                  // 獲取物件 scale 的比例
                  const scaleX = obj.scaleX;
                  const scaleY = obj.scaleY;

                  if (!scaleX || !scaleY) return;
                  const maxX =
                    flexContainerWidth -
                    obj.width * scaleX +
                    (flexContainer.left ?? 0);
                  const MaxyY =
                    flexContainer.height -
                    obj.height * scaleY +
                    (flexContainer.top ?? 0);

                  obj.set({
                    left: Math.min(
                      maxX,
                      Math.max(flexContainer.left ?? 0, obj.left)
                    ),
                    top: Math.min(
                      MaxyY,
                      Math.max(flexContainer.top ?? 0, obj.top)
                    ),
                  });

                  obj.setCoords();
                });

                canvas.add(flexButton);
                console.log("執行一次");
              }
            });
          }
        });
      }
    } else {
      if (isFlexButtonShouldRender && done) {
        const thisPageNameCardData = nameCardV3Data[currentPage];
        const flexContainer = canvas
          .getObjects()
          .find((item) => item.name === `flexContainer-${currentPage}`);
        if (!flexContainer) return;
        if (
          thisPageNameCardData &&
          thisPageNameCardData.body &&
          thisPageNameCardData.body?.contents &&
          thisPageNameCardData.body.contents.length > 0
        ) {
          thisPageNameCardData.body.contents.forEach(
            (flexComponent, flexComponentIndex) => {
              if (
                flexComponent.type === "box" &&
                thisPageNameCardData.body &&
                thisPageNameCardData.body.contents.length > flexComponentIndex
              ) {
                const thisFlexButton = canvas
                  .getObjects()
                  .find(
                    (item) =>
                      item.name ===
                      `flexContainer-${currentPage}_flexButton${flexComponentIndex}`
                  );
                console.log("thisFlexButton", thisFlexButton);
                if (thisFlexButton) return;

                if (
                  flexComponent.type === "box" &&
                  flexComponent.action?.type === "uri"
                ) {
                  const width = () => {
                    return (
                      parseInt(flexComponent.width?.split("px")[0] ?? "150") *
                      scaleFactor
                    );
                  };

                  const height = () => {
                    return parseInt(
                      flexComponent.height?.split("px")[0] ?? "50"
                    ) > maxHeightRef.current
                      ? maxHeightRef.current
                      : parseInt(flexComponent.height?.split("px")[0] ?? "50") *
                          scaleFactor;
                  };
                  const top = Math.round(
                    parseInt(flexComponent.offsetTop?.split("px")[0] ?? "25") *
                      scaleFactor +
                      25
                  ); // 25為顯示時往下降的高度

                  const left = Math.round(
                    parseInt(flexComponent.offsetStart?.split("px")[0] ?? "0") *
                      scaleFactor +
                      (flexContainer.left ?? 0)
                  );
                  console.log("幾小細節", flexContainer);
                  const flexButton = new fabric.Rect({
                    width: width(),
                    height: height(),
                    top: top,
                    left: left,
                    fill: "#00FF0080",
                    selectable: true,
                    name: `flexContainer-${currentPage}_flexButton${flexComponentIndex}`,
                  });

                  // 這邊是註冊移動事件 ，限制 flexButton rect 不能移動出去 該 flexContainer 的範圍
                  flexButton.on("moving", function (e) {
                    const obj = e.transform?.target;
                    if (
                      !obj ||
                      !obj.width ||
                      !obj.height ||
                      !flexContainer.height ||
                      !obj.left ||
                      !obj.top
                    )
                      return;

                    // 獲取物件 scale 的比例
                    const scaleX = obj.scaleX;
                    const scaleY = obj.scaleY;

                    if (!scaleX || !scaleY) return;
                    const maxX =
                      flexContainerWidth -
                      obj.width * scaleX +
                      (flexContainer.left ?? 0);
                    const MaxyY =
                      flexContainer.height -
                      obj.height * scaleY +
                      (flexContainer.top ?? 0);

                    obj.set({
                      left: Math.min(
                        maxX,
                        Math.max(flexContainer.left ?? 0, obj.left)
                      ),
                      top: Math.min(
                        MaxyY,
                        Math.max(flexContainer.top ?? 0, obj.top)
                      ),
                    });

                    obj.setCoords();
                  });

                  canvas.add(flexButton);
                }
              }
            }
          );
        }
      }
    }

    canvas.requestRenderAll();
  }, [maxHeightInfo, canvas, shouldRender, isUpdateCoverImgUrl]);

  // 當 flex message josn data 有更新變動的時候
  useEffect(() => {
    if (canvas === null) return;
    console.log(canvas.getObjects());
    // 當前的 Bubble 數量
    const currentBubbleCount = nameCardV3Data.length;
    // 上一次的 Bubble 數量
    const previousBubbleCount = msgContentRef.current.length;
    console.log("currentBubbleCount", currentBubbleCount);
    console.log("previousBubbleCount", previousBubbleCount);
    const isFlexContainerShouldRender =
      currentBubbleCount !== previousBubbleCount;
    const isFlexButtonShouldRender = () => {
      let isUpdateFlexButton = false;
      if (nameCardV3Data.length > 0 && msgContentRef.current.length > 0) {
        nameCardV3Data.forEach((newCflexBubble, newCflexBubbleIndex) => {
          msgContentRef.current.forEach(
            (oldCflexBubble, oldCflexBubbleIndex) => {
              if (
                newCflexBubble.body &&
                newCflexBubble.body.contents &&
                newCflexBubble.body.contents.length > 0 &&
                oldCflexBubble.body &&
                oldCflexBubble.body.contents &&
                oldCflexBubble.body.contents.length > 0
              ) {
                if (
                  newCflexBubble.body.contents.length !==
                  oldCflexBubble.body.contents.length
                ) {
                  return (isUpdateFlexButton =
                    newCflexBubble.body.contents.length !==
                    oldCflexBubble.body.contents.length);
                }
              }
            }
          );
        });
      }
      return nameCardV3Data !== msgContentRef.current && isUpdateFlexButton;
    };

    const isUpdateCoverImgUrl = () => {
      let res: { isUpdateCoverImgUrl: boolean; flexContainerIndex?: number } = {
        isUpdateCoverImgUrl: false,
        flexContainerIndex: undefined,
      };
      nameCardV3Data.forEach((item, index) => {
        if (hasImageInthisBubble(item)) {
          // 檢查是否存在圖片，並且進一步檢查是否需要更新圖片 URL
          const currentImageUrl = (item.body?.contents[0] as FlexImage).url;
          const previousImageUrl =
            msgContentRef.current.length > index &&
            msgContentRef.current[index].body &&
            msgContentRef.current[index].body?.contents.length !== 0 &&
            (msgContentRef.current[index]?.body?.contents[0] as FlexImage).url;

          if (currentImageUrl !== previousImageUrl) {
            // 如果 URL 有更改，執行相應的邏輯，例如更新狀態或執行其他操作
            console.log(`Image URL at index ${index} has changed. Updating...`);
            // 在這裡添加更新邏輯
            res = { isUpdateCoverImgUrl: true, flexContainerIndex: index };
          }
        }
      });
      return res;
    };
    const res = isUpdateCoverImgUrl();
    setIsUpdateCoverImgUrl(res);
    setShouldRender((preVal) => ({
      isFlexContainerShouldRender: isFlexContainerShouldRender,
      isFlexButtonShouldRender: isFlexButtonShouldRender(),
    }));

    // 獲取當前 canvs 最大的高度
    setMaxHeightInfo((preVal) => ({ ...preVal, done: false }));
    calculateMaxHeight(flexContainerWidth).then((currentMaxHeight) => {
      console.log("currentMaxHeight", currentMaxHeight);
      console.log("maxHeightInfo.maxHeight", maxHeightInfo.maxHeight);
      const isupdateFlexContainerHeight =
        currentMaxHeight !== maxHeightInfo.maxHeight;
      if (!isUpdate && step1 && currentMaxHeight === 0) {
        setMaxHeightInfo({
          done: true,
          isUpdateMaxHeightInfo: isupdateFlexContainerHeight,
          maxHeight: 585,
        });
      } else {
        setMaxHeightInfo({
          done: true,
          isUpdateMaxHeightInfo: isupdateFlexContainerHeight,
          maxHeight: currentMaxHeight,
        });
      }
      if (currentMaxHeight !== 0) {
        msgContentRef.current = nameCardV3Data;
      }
    });
  }, [nameCardV3Data, canvas]);

  // add flex message page
  const addPage = () => {
    if (nameCardV3Data.length >= 6) return message.warning("目前已經新增 6 頁"); // 24.10.30 SCH
    if (nameCardV3Data.length >= 1) {
      if (is_normal && role !== 2) return setIsPermissionMsgModalOpen(true); // 24.10.30 SCH
    }

    const pageNum = nameCardV3Data.length + 1;
    const newNameCardV3Data: CFlexBubble[] = JSON.parse(
      JSON.stringify(nameCardV3Data)
    );
    newNameCardV3Data.push(newPage(pageNum));
    setNameCardV3Data(newNameCardV3Data);
    setCurrentPage(newNameCardV3Data.length - 1);
  };

  // delete flex message page
  const deletePage = (_pageToDelete: number) => {
    if (msgContentRef.current.length === 1) {
      alert("只有一頁flex message 不能刪除");
      handleDeletePageModalCancel();
      return;
    }
    const newContent: CFlexBubble[] = JSON.parse(
      JSON.stringify(msgContentRef.current)
    );
    newContent.splice(_pageToDelete, 1);
    try {
      const updatedFlexBubbles: CFlexBubble[] = newContent.map((flexBubble) => {
        if (flexBubble.body?.contents && flexBubble.body.contents.length > 0) {
          const firstImage = flexBubble.body.contents[0];
          if (firstImage.type === "image") {
            const isImageUrlNeedsUpdate = firstImage.url.includes(
              currentConfig.REACT_APP_IMAGEPATH
            );
            if (isImageUrlNeedsUpdate) {
              const updateAspectRatio = async () => {
                const aspectRatioResult = await calculateAspectRatio(
                  firstImage.url
                );
                const { aspectRatioHeight, aspectRatioWidth } =
                  aspectRatioResult;
                firstImage.aspectRatio = `${aspectRatioWidth}:${aspectRatioHeight}`;
              };

              updateAspectRatio();
            }
          }
        }
        return flexBubble;
      });

      // 處理canvas 上面呈現的內容
      if (canvas) {
        let currentFlexContainerInCanvas: fabric.Object[] = [];
        // 刪除是該頁 name card 的 cavnas 物件
        canvas.getObjects().forEach((item) => {
          if (
            item.name &&
            item.name.indexOf(`flexContainer-${_pageToDelete}`) !== -1
          ) {
            canvas.remove(item);
          } else {
            if (item.name && item.name.indexOf("_") === -1)
              currentFlexContainerInCanvas.push(item);
          }
        });
        console.log(
          "currentFlexContainerInCanvas",
          currentFlexContainerInCanvas
        );

        updatedFlexBubbles.forEach((item, index) => {
          (currentFlexContainerInCanvas[index] as fabric.Rect).set({
            name: `flexContainer-${index}`,
            width: flexContainerWidth,
            // height: maxHeightInfo.maxHeight ? maxHeightInfo.maxHeight : 585,
            height: maxHeightInfo.maxHeight,
            fill: currentFlexContainerInCanvas[index].fill,
            strokeWidth: 2,
            top: 25,
            left: index * 25 + index * 390,
            rx: 10,
            ry: 10,
          });
          currentFlexContainerInCanvas[index].setCoords();
        });
      }
      setCurrentPage(0);
      handleDeletePageModalCancel();
      setNameCardV3Data(updatedFlexBubbles);
    } catch (error) {
      console.error("Error", error);
    }
  };

  // select input choose  which page in now flex message
  const handleChangePage = (value: number) => {
    setCurrentPage(value);
    const flexBubble = nameCardV3Data[value];
    if (flexBubble.body && flexBubble.body.contents) {
      // 检查是否存在内容
      if (flexBubble.body.contents.length > 0) {
        flexBubble.body.contents.forEach((element, index) => {
          // 检查是否 flex component type=image，并且是否包含 url 属性
          if (element.type === "image" && element.url) {
            const imageUrl = element.url;
            setImgUrl(imageUrl);
          } else {
            setImgUrl(null);
          }
        });
      } else {
        setImgUrl(null);
      }
    } else {
      setImgUrl(null);
    }
  };

  // 計算底圖比例
  const calculateAspectRatio = (
    _imageURL: string
  ): Promise<{ aspectRatioWidth: number; aspectRatioHeight: number }> => {
    return new Promise((resolve) => {
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.src = _imageURL + "?time=" + new Date().valueOf();

      image.onload = () => {
        let aspectRatioWidth = 0;
        let aspectRatioHeight = 0;
        const imageAspectRatio = image.width / image.height;
        const multiplier = Math.ceil(1 / imageAspectRatio);
        // line flex message 寬度都是固定的
        // flex message json aspectRatio 不允許小於1的值
        aspectRatioWidth = parseFloat(
          (imageAspectRatio * multiplier).toFixed(2)
        );
        aspectRatioHeight = 1 * multiplier;
        console.log({
          aspectRatioWidth: aspectRatioWidth,
          aspectRatioHeight: aspectRatioHeight,
        });
        resolve({
          aspectRatioWidth: aspectRatioWidth,
          aspectRatioHeight: aspectRatioHeight,
        });
      };
    });
  };

  // add image to flex message
  const addImageHandler = async (_currentPage: number, urlVal: string) => {
    // 取得目前input值
    if (!urlVal) return;
    const currentImgUrl = urlVal;
    if (currentImgUrl === "" || currentImgUrl === undefined) return;
    // 將圖片url新增到該頁 flex message 裡
    // 拷貝目前 flex message 訊息
    const newContent: CFlexBubble[] = JSON.parse(
      JSON.stringify(nameCardV3Data)
    );
    // const currentImgUrl = urlVal;
    try {
      const result = await calculateAspectRatio(currentImgUrl);
      const { aspectRatioWidth, aspectRatioHeight } = result;
      //image flex message 模板
      const imageFlexMsg: FlexComponent = {
        type: "image",
        url: currentImgUrl,
        size: "full",
        aspectRatio: `${aspectRatioWidth}:${aspectRatioHeight}`,
        gravity: "top",
        aspectMode: "cover",
      };
      console.log(imageFlexMsg);
      // 將image 新增到 flex message 中
      const itemNum = newContent[_currentPage].body?.contents.length;
      console.log(newContent);
      console.log(itemNum);
      console.log(newContent[_currentPage].body);
      if (itemNum !== 0) {
        newContent[_currentPage].body?.contents.unshift(imageFlexMsg);
      } else {
        newContent[_currentPage].body?.contents.push(imageFlexMsg);
      }
      setNameCardV3Data(newContent);
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  // 檢查是不是有效的圖片
  useEffect(() => {
    if (!imgUrl) return;
    if (imgUrl === "" || imgUrl === undefined) return;
    const img = new Image();

    img.crossOrigin = "anonymous";

    img.onload = () => {
      let hasBackground: boolean = false;
      setIsValidateImageUrl(true);
      msgContentRef.current[currentPage].body?.contents.forEach(
        (element, index) => {
          if (element.type === "image") {
            hasBackground = true;
            const tmep: CFlexBubble[] = JSON.parse(
              JSON.stringify(msgContentRef.current)
            );
            (tmep[currentPage].body?.contents[index] as FlexImage).url =
              imgUrl + "?time=" + new Date().valueOf();
            console.log(tmep);
            return setNameCardV3Data(tmep);
          }
        }
      );

      !hasBackground && addImageHandler(currentPage, imgUrl);
    };
    img.onerror = () => {
      setIsValidateImageUrl(false);
    };
  }, [imgUrl]);

  const altTextHandler = (_altText: string) => {
    setNameCardV3Setting({ altText: _altText });
  };

  const cardTitleHandler = (_cardTitle: string) => {
    setNameCardV3Setting({ cardTitle: _cardTitle });
  };

  const addButton = (
    _currentPage: number,
    _buttonType: "urlButton" | "phoneButton" | "shareNameCardButton"
  ) => {
    // 將圖片url新增到該頁 flex message 裡
    // 拷貝目前 flex message 訊息
    const newcontent: CFlexBubble[] = JSON.parse(
      JSON.stringify(nameCardV3Data)
    );

    const buttonFlexMsg = (): FlexBox => {
      let resultButtonFlexMsg: FlexBox;
      switch (_buttonType) {
        case "urlButton":
          resultButtonFlexMsg = fullImgModeNameCardConfig.linkUrlButton(
            brandState.liff_id_promo
          ); // 24.11.21 SCH ★
          break;
        case "shareNameCardButton":
          resultButtonFlexMsg = fullImgModeNameCardConfig.shareNameCardButton(
            cardHashID,
            currentConfig.REACT_APP_LIFFID
          );
          break;
        case "phoneButton":
          resultButtonFlexMsg = fullImgModeNameCardConfig.phoneButton;
          break;
        default:
          resultButtonFlexMsg = fullImgModeNameCardConfig.linkUrlButton(
            brandState.liff_id_promo
          ); // 24.11.21 SCH ★
          break;
      }
      return resultButtonFlexMsg;
    };

    // 將 button 新增到 flex message 中
    newcontent[_currentPage].body?.contents.push(buttonFlexMsg());
    setNameCardV3Data(newcontent);
  };

  const currentPageBackgroundImageUrl = () => {
    const element: FlexComponent | undefined = nameCardV3Data[
      currentPage
    ].body?.contents.find((item) => item.type === "image");
    if (element !== undefined) {
      const imgUrl = (element as FlexImage).url;
      return imgUrl;
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (btnUrl === "" || btnUrl === null || buttonIndex === null) return;
    const newcontent: CFlexBubble[] = JSON.parse(
      JSON.stringify(msgContentRef.current)
    );
    const button: FlexBox = newcontent[currentPage].body?.contents[
      buttonIndex
    ] as FlexBox;

    if (button?.type === "box" && button.action?.type === "uri") {
      let newUri = btnUrl;
      if (isPhoneButton(button)) {
        // alert( btnUrl);
        newUri = btnUrl.startsWith("tel:+886") ? btnUrl : `tel:+886${btnUrl}`;
      }

      if (button.action.uri !== newUri) {
        button.action.uri = newUri;
        setNameCardV3Data(newcontent);
      }
    }
  }, [btnUrl]);

  const deleteButton = (_currentPage: number, _buttonIndex: number) => {
    console.log("_currentPage", _currentPage);
    console.log("_buttonIndex", _buttonIndex);
    const newcontent: CFlexBubble[] = JSON.parse(
      JSON.stringify(msgContentRef.current)
    );

    if (newcontent[_currentPage].body?.contents) {
      if (
        newcontent[_currentPage].body?.contents?.[_buttonIndex]?.type === "box"
      ) {
        newcontent[_currentPage].body?.contents.splice(_buttonIndex, 1);
        setNameCardV3Data(newcontent);
        setBtnUrl(null);
        setButtonIndex(null);
        handleDeleteButtonModalCancel();
      }
    }
  };

  const uploadImgHandler = async (option: UploadRequestOption) => {
    const { file, onProgress, onError, onSuccess } = option;

    const errorEvent = new ProgressEvent("error", {
      lengthComputable: false,
      loaded: 0,
      total: 0,
    });

    const resultFile = new FormData();
    resultFile.append("image", file);
    try {
      if (accessToken) {
        const { data } = await uploadImageToS3(accessToken, resultFile);
        if (data)
          if (data.data.success) {
            console.log("文件上傳成功");
            const { image_url } = data.data.data;
            console.log(image_url);

            onProgress && onProgress({ percent: 100 });
            onSuccess && onSuccess(image_url);
            const image = new Image();
            image.src = `${currentConfig.REACT_APP_IMAGEBASEURL}${image_url}`;
            console.log(image);
            image.onload = () => {
              let hasBackground: boolean = false;
              nameCardV3Data[currentPage].body?.contents.forEach(
                (element, index) => {
                  if (element.type === "image") {
                    hasBackground = true;
                    const tmep: CFlexBubble[] = JSON.parse(
                      JSON.stringify(msgContentRef.current)
                    );
                    (tmep[currentPage].body?.contents[index] as FlexImage).url =
                      image.src + "?time=" + new Date().valueOf();
                    console.log(tmep);
                    return setNameCardV3Data(tmep);
                  }
                }
              );

              !hasBackground && addImageHandler(currentPage, image.src);
            };
            image.onerror = () => {
              setIsValidateImageUrl(false);
            };
            // 處理成功的邏輯
          } else {
            console.error("文件上傳失敗", data.data.message);
            onError && onError(errorEvent);
          }
      }
    } catch (error) {
      console.error("文件上傳失敗", error);
      onError && onError(errorEvent);
    }
  };

  const props: UploadProps = {
    name: "image",
    accept: "image/png, image/jpeg",
    multiple: false,
    customRequest: (option) => uploadImgHandler(option),
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} 圖片上傳成功.`);
        setImgUrl(info.file.response);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} 圖片上傳失敗.`);
      }
    },
  };

  const deleteButtonHandler = (_buttonIndex: number) => {
    const tempFlexMsgContent: CFlexBubble[] = JSON.parse(
      JSON.stringify(nameCardV3Data)
    );

    const newFlexMsgContent: CFlexBubble[] = tempFlexMsgContent.map(
      (cflexBubble, cflexBubbleIndex) => {
        if (
          cflexBubbleIndex !== currentPage ||
          !cflexBubble.body ||
          !cflexBubble.body.contents
        ) {
          return cflexBubble;
        }
        const temp: CFlexBubble = JSON.parse(JSON.stringify(cflexBubble));
        if (temp && temp.body && temp.body.contents) {
          console.log(
            temp.body.contents.filter((item, index) => index !== _buttonIndex)
          );
          cflexBubble.body.contents = temp.body.contents.filter(
            (item, index) => index !== _buttonIndex
          );
        }

        console.log(cflexBubble);

        return cflexBubble;
      }
    );
    setNameCardV3Data(newFlexMsgContent);
    if (canvas) {
      canvas.getObjects().find((item) => {
        if (
          item.name &&
          item.name === `flexContainer-${currentPage}_flexButton${_buttonIndex}`
        ) {
          canvas.remove(item);
        }
      });

      const currentFlexContainerAllButton: fabric.Rect[] = canvas
        .getObjects()
        .filter(
          (item) =>
            item.name &&
            item.name.indexOf(`flexContainer-${currentPage}_flexButton`) !== -1
        );

      if (
        newFlexMsgContent[currentPage].body &&
        newFlexMsgContent[currentPage].body?.contents &&
        newFlexMsgContent[currentPage].body?.contents.length !== 0 &&
        currentFlexContainerAllButton.length !== 0
      ) {
        newFlexMsgContent[currentPage].body?.contents.forEach((item, index) => {
          if (item.type === "box" && item.action?.type === "uri") {
            console.log(currentFlexContainerAllButton);
            currentFlexContainerAllButton[
              Math.max(
                hasImageInthisBubble(newFlexMsgContent[currentPage])
                  ? index - 1
                  : index,
                0
              )
            ].set({
              ...currentFlexContainerAllButton[
                Math.max(
                  hasImageInthisBubble(newFlexMsgContent[currentPage])
                    ? index - 1
                    : index,
                  0
                )
              ],
              name: `flexContainer-${currentPage}_flexButton${index}`,
            });
          }
        });
      }
    }
  };

  const onFlexButtonLabelChangeHandler = (
    _currentPage: number,
    _flexButtonIndex: number,
    _newLabel: string
  ) => {
    console.log(
      "currentPage:",
      _currentPage,
      "flexButtonIndex:",
      _flexButtonIndex,
      "newLabel",
      _newLabel
    );
    const tempFlexMsgContent: CFlexBubble[] = JSON.parse(
      JSON.stringify(nameCardV3Data)
    );
    const newFlexMsgContent = tempFlexMsgContent.map(
      (cflexBubble, cflexBubbleIndex) => {
        if (
          cflexBubbleIndex !== _currentPage ||
          !cflexBubble.body ||
          !cflexBubble.body.contents
        ) {
          return cflexBubble;
        }

        const targetFlexBox = cflexBubble.body.contents[
          _flexButtonIndex
        ] as FlexBox;
        if (targetFlexBox.action) {
          targetFlexBox.action.label = _newLabel;
        }
        return cflexBubble;
      }
    );
    console.log("newFlexMsgContent", newFlexMsgContent);
    setNameCardV3Data(newFlexMsgContent);
  };

  const onFlexButtonUrlChangeHandler = (
    _currentPage: number,
    _flexButtonIndex: number,
    _newUrl: string
  ) => {
    console.log(
      "currentPage:",
      _currentPage,
      "flexButtonIndex:",
      _flexButtonIndex,
      "newUrl",
      _newUrl
    );

    const tempFlexMsgContent: CFlexBubble[] = JSON.parse(
      JSON.stringify(nameCardV3Data)
    );
    const newFlexMsgContent = tempFlexMsgContent.map(
      (cflexBubble, cflexBubbleIndex) => {
        if (
          cflexBubbleIndex !== _currentPage ||
          !cflexBubble.body ||
          !cflexBubble.body.contents
        ) {
          return cflexBubble;
        }

        const targetFlexBox = cflexBubble.body.contents[
          _flexButtonIndex
        ] as FlexBox;
        if (targetFlexBox.action && targetFlexBox.action.type === "uri") {
          targetFlexBox.action.uri = _newUrl;
        }
        return cflexBubble;
      }
    );
    console.log("newFlexMsgContent", newFlexMsgContent);
    setNameCardV3Data(newFlexMsgContent);
  };

  const hasImageInthisBubble = (_flexbubble: CFlexBubble) => {
    return _flexbubble.body
      ? _flexbubble.body.contents.some(
          (flexComponent) => flexComponent.type === "image"
        )
      : false;
  };

  const scrollToPositionLeftRight = (
    _previewBlockDiv: HTMLDivElement,
    _currentPage: number
  ) => {
    const currentDivScrollTop = _previewBlockDiv.scrollTop;
    const currentDivScrollLeft = _previewBlockDiv.scrollLeft;

    _previewBlockDiv.scrollTo({
      top: currentDivScrollTop,
      left: _currentPage * 25 + _currentPage * 390,
      behavior: "smooth",
    });
  };

  const scrollToPositionUpDown = (
    mode: "up" | "down",
    _bodyBlockDiv: HTMLDivElement,
    _currentPage: number
  ) => {
    const currentDivScrollLeft = _bodyBlockDiv.scrollLeft;
    if (
      !infoBlockRef.current ||
      !previewBlockRef.current ||
      !pageEditBlockRef.current
    )
      return;

    const blockOffsets = {
      infoBlock: 0,
      previewBlock: previewBlockRef.current?.offsetTop - 200,
      pageEditBlock: pageEditBlockRef.current?.offsetTop - 80,
    };

    const blockOffsetsKey = Object.keys(blockOffsets);
    const currentIndex = blockOffsetsKey.findIndex(
      (item) => item === currentBlockRef.current
    );

    const moveUp = () => {
      if (currentIndex === 0) return;
      const targetIndex =
        (currentIndex - 1 + blockOffsetsKey.length) % blockOffsetsKey.length;
      currentBlockRef.current = blockOffsetsKey[targetIndex] as
        | "infoBlock"
        | "previewBlock"
        | "pageEditBlock";
      _bodyBlockDiv.scrollTo({
        top: blockOffsets[currentBlockRef.current],
        left: currentDivScrollLeft,
        behavior: "smooth",
      });
    };

    const moveDown = () => {
      if (currentIndex === blockOffsetsKey.length - 1) return;
      const targetIndex = (currentIndex + 1) % blockOffsetsKey.length;
      currentBlockRef.current = blockOffsetsKey[targetIndex] as
        | "infoBlock"
        | "previewBlock"
        | "pageEditBlock";

      _bodyBlockDiv.scrollTo({
        top: blockOffsets[currentBlockRef.current],
        left: currentDivScrollLeft,
        behavior: "smooth",
      });
    };

    if (mode === "up") {
      moveUp();
    } else {
      moveDown();
    }
  };

  // 檢查是否為電話按鈕
  const isPhoneButton = (item: FlexBox) => {
    return (
      item.action &&
      item.action.type === "uri" &&
      item.action.label === fullImgModeNameCardButtonLabelConfig.phoneButton &&
      item.contents &&
      item.contents.length > 0 &&
      item.contents[0].type === "text" &&
      item.contents[0].text ===
        fullImgModeNameCardButtonType.callphoneButton.text
    );
  };

  const handleOk = () => {
    setIsPermissionMsgModalOpen(false);
  };

  const handleCancel = () => {
    setIsPermissionMsgModalOpen(false);
  };

  useEffect(() => {
    if (nameCardV3Data.length > 0) {
      nameCardV3Data[currentPage].body?.contents
        .filter((flexComponent) => flexComponent.type !== "image")
        .forEach((item, buttonIndex) => {
          if (item.type === "box" && item.action?.type === "uri") {
            // 設置按鈕文字
            form.setFieldValue(
              `button${currentPage}-${buttonIndex}-Text`,
              item.action.label
            );

            // 設置按鈕連結/電話
            const fieldName = isPhoneButton(item)
              ? `button${currentPage}-${buttonIndex}-PhoneNumber`
              : `button${currentPage}-${buttonIndex}-Url`;

            const fieldValue = isPhoneButton(item)
              ? item.action.uri.replace("tel:+886", "") // 移除前綴顯示純數字
              : item.action.uri;

            form.setFieldValue(fieldName, fieldValue);
          }
        });
    }
  }, [nameCardV3Data, form, currentPage]);

  useEffect(() => {
    if (nameCardV3Setting) {
      form.setFieldsValue(nameCardV3Setting);
    }
  }, [nameCardV3Setting, form]);

  useEffect(() => {
    if (form) {
      console.log("form", form.getFieldsValue());
      setNameCardV3Form(form);
    }
  }, [form]);

  return (
    <Row justify="center" className={classes.container}>
      <Col
        lg={8}
        md={24}
        sm={24}
        xs={24}
        span={8}
        className={classes.controlBar}
      >
        {role >= 2 || eagle ? <CategorySelector /> : null}
        <Form
          form={form}
          initialValues={{
            ...nameCardV3Setting,
            ...nameCardV3Data[currentPage].body?.contents
              .filter((flexComponent) => flexComponent.type !== "image")
              .reduce((acc, item, buttonIndex) => {
                if (item.type === "box" && item.action?.type === "uri") {
                  return {
                    ...acc,
                    [`button${currentPage}-${buttonIndex}-Text`]:
                      item.action.label,
                    [`button${currentPage}-${buttonIndex}-${isPhoneButton(item) ? "PhoneNumber" : "Url"}`]:
                      item.action.uri,
                  };
                }
                return acc;
              }, {}),
          }}
          layout="vertical"
          style={{ fontSize: "16px" }}
        >
          <div className={classes.cardTitle}>{nameCardV3Setting.cardTitle}</div>

          <Form.Item
            label={<span className={classes.label}>電子名片名稱</span>}
            name="cardTitle"
            rules={[
              {
                required: true,
                validator: async (_: unknown, value: string) => {
                  if (!value) {
                    return Promise.reject("請輸入電子名片名稱");
                  }
                  // 檢查是否包含任何空白字元
                  if (/\s/.test(value)) {
                    return Promise.reject("電子名片名稱不可包含空白字元");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              type="text"
              className={classes.input}
              placeholder="請輸入電子名片名稱 "
              onChange={(e) => cardTitleHandler(e.target.value)}
              defaultValue={nameCardV3Setting && nameCardV3Setting.cardTitle}
              value={nameCardV3Setting && nameCardV3Setting.cardTitle}
            />
          </Form.Item>

          <Form.Item
            label={<span className={classes.label}>LINE 訊息通知文字</span>}
            name="altText"
            rules={[
              {
                required: true,
                validator: async (_: unknown, value: string) => {
                  if (!value) {
                    return Promise.reject("請輸入要顯示在 LINE 的訊息通知文字");
                  }
                  // 檢查是否包含任何空白字元
                  if (/\s/.test(value)) {
                    return Promise.reject("訊息通知文字不可包含空白字元");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              type="text"
              className={classes.input}
              placeholder="請輸入要顯示在 LINE 的訊息通知文字"
              onChange={(e) => altTextHandler(e.target.value)}
              defaultValue={nameCardV3Setting.altText}
              value={nameCardV3Setting && nameCardV3Setting.altText}
            />
          </Form.Item>
          {/* <Form layout="inline"> */}
          <Form.Item
            className={classes.formItem}
            label={<span className={classes.label}>切換頁面</span>}
          >
            <div className={classes.flexRow}>
              <div
                className={`${classes.defaultButtonContainer} ${classes.flexCol}`}
              >
                <Button
                  onClick={addPage}
                  type="primary"
                  className={classes.defaultButton}
                >
                  <div className={classes.buttonWord}>新增頁面</div>
                </Button>
              </div>
              <div className={`${classes.selectInputItem} ${classes.flexCol}`}>
                <Select
                  value={currentPage}
                  onChange={(e) => handleChangePage(e)}
                >
                  {nameCardV3Data.map((_, index) => (
                    <Select.Option key={index} value={index}>
                      頁面 {index + 1}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              {nameCardV3Data.length > 1 && (
                <div
                  className={`${classes.defaultButtonContainer} ${classes.originButtonHover} ${classes.flexCol}`}
                >
                  <Button
                    className={`${classes.defaultButton} ${classes.originButton}`}
                    type="primary"
                    onClick={showDeletePageModal}
                  >
                    <div className={classes.buttonWord}>刪除此頁面</div>
                  </Button>
                </div>
              )}
            </div>
          </Form.Item>
          {/* </Form> */}

          <div className={classes.pageEditer}>
            <div className={classes.pageEditerTitle}>
              <div>
                <span>頁面編輯器</span>
              </div>
              <div>{"</>"}</div>
            </div>
            <div className={classes.pagepadding}>
              {/* 背景圖上傳 */}
              <Form.Item
                label={<span className={classes.label}>背景圖上傳</span>}
              >
                <Upload {...props}>
                  <Button
                    className={classes.uploadButton}
                    icon={<UploadOutlined rev={""} />}
                  >
                    上傳圖片
                  </Button>
                </Upload>
              </Form.Item>

              {/* 目前選取的連結按鈕 URL 連結 */}
              <Form.Item
                label={
                  <span className={classes.label}>
                    目前選取的連結按鈕 URL 連結
                  </span>
                }
              >
                <Input
                  type="text"
                  disabled={isShareNameCardButton}
                  onChange={(e) => {
                    setBtnUrl(e.target.value);
                  }}
                  value={btnUrl ? handlePhoneNumber(btnUrl, true) : ""}
                ></Input>
              </Form.Item>

              <div className={classes.buttonEditBlock}>
                {nameCardV3Data[currentPage].body?.contents
                  .filter(
                    (flexComponent, flexComponentIndex) =>
                      flexComponent.type !== "image"
                  )
                  .map(
                    (item, buttonIndex) =>
                      item.type === "box" && (
                        <div
                          className={classes.flexMsgButtonItem}
                          key={`currentPage-${currentPage}-buttonIndex-${buttonIndex}`}
                        >
                          <Form.Item
                            name={`button${currentPage}-${buttonIndex}-Text`}
                            className={`${classes.flexCol} ${classes.formItem}`}
                            label={<div>{`按鈕 ${buttonIndex + 1} 文字`}</div>}
                            rules={[
                              {
                                required: true,
                                validator: async (
                                  _: unknown,
                                  value: string
                                ) => {
                                  if (!value) {
                                    return Promise.reject("請輸入按鈕文字");
                                  }
                                  // 檢查是否包含任何空白字元
                                  if (/\s/.test(value)) {
                                    return Promise.reject(
                                      "按鈕文字不可包含空白字元"
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              disabled={
                                (item.action &&
                                  item.action.type === "uri" &&
                                  item.action.label ===
                                    fullImgModeNameCardButtonLabelConfig.shareNameCardButton) ||
                                (item.contents &&
                                  item.contents.length > 0 &&
                                  item.contents[0].type === "text" &&
                                  (item.contents[0].text ===
                                    fullImgModeNameCardButtonType
                                      .shareNameCardButton.text ||
                                    item.contents[0].text ===
                                      fullImgModeNameCardButtonType
                                        .callphoneButton.text))
                              }
                              type="text"
                              defaultValue={item.action?.label}
                              value={item.action?.label}
                              onChange={(e) => {
                                console.log(e.target.value);
                                onFlexButtonLabelChangeHandler(
                                  currentPage,
                                  hasImageInthisBubble(
                                    nameCardV3Data[currentPage]
                                  )
                                    ? buttonIndex + 1
                                    : buttonIndex,
                                  e.target.value
                                );
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            className={`${classes.flexCol} ${classes.formItem}`}
                            label={<div>{`按鈕 ${buttonIndex + 1} 連結`}</div>}
                            name={
                              isPhoneButton(item)
                                ? `button${currentPage}-${buttonIndex}-PhoneNumber`
                                : `button${currentPage}-${buttonIndex}-Url`
                            }
                            rules={[
                              {
                                required: true,
                                validator: async (
                                  _: unknown,
                                  value: string
                                ) => {
                                  if (!value) {
                                    return Promise.reject(
                                      isPhoneButton(item)
                                        ? "請輸入電話號碼"
                                        : "請輸入按鈕連結"
                                    );
                                  }
                                  if (isPhoneButton(item)) {
                                    // 移除可能的前綴以進行驗證
                                    const phoneNumber = value.replace(
                                      /^(\+886|0)/,
                                      ""
                                    );

                                    // 台灣手機: 9開頭後面8碼
                                    const mobilePattern = /^9\d{8}$/;
                                    // 市話: 區碼1-2碼 + 電話號碼6-8碼
                                    const telPattern = /^[2-8]\d{7,8}$/;
                                    // 0800服務電話: 移除0後為800
                                    const servicePattern = /^800\d{6}$/;

                                    if (
                                      !mobilePattern.test(phoneNumber) &&
                                      !telPattern.test(phoneNumber) &&
                                      !servicePattern.test(phoneNumber)
                                    ) {
                                      return Promise.reject(
                                        "請輸入有效的台灣電話號碼格式"
                                      );
                                    }
                                  } else if (!isValidHttpUrl(value)) {
                                    return Promise.reject("請輸入有效的 URL");
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              disabled={
                                item.action &&
                                item.action.type === "uri" &&
                                item.action.label ===
                                  fullImgModeNameCardButtonLabelConfig.shareNameCardButton &&
                                item.contents &&
                                item.contents.length > 0 &&
                                item.contents[0].type === "text" &&
                                item.contents[0].text ===
                                  fullImgModeNameCardButtonType
                                    .shareNameCardButton.text
                              }
                              type={
                                item.action &&
                                item.action.type === "uri" &&
                                item.action.label ===
                                  fullImgModeNameCardButtonLabelConfig.phoneButton &&
                                item.contents &&
                                item.contents.length > 0 &&
                                item.contents[0].type === "text" &&
                                item.contents[0].text ===
                                  fullImgModeNameCardButtonType.callphoneButton
                                    .text
                                  ? "number"
                                  : "text"
                              }
                              defaultValue={
                                item.action?.type === "uri"
                                  ? handlePhoneNumber(item.action.uri, true)
                                  : ""
                              }
                              value={
                                item.action?.type === "uri"
                                  ? handlePhoneNumber(item.action.uri, true)
                                  : ""
                              }
                              addonBefore={
                                item.action &&
                                item.action.type === "uri" &&
                                item.action.label ===
                                  fullImgModeNameCardButtonLabelConfig.phoneButton &&
                                item.contents &&
                                item.contents.length > 0 &&
                                item.contents[0].type === "text" &&
                                item.contents[0].text ===
                                  fullImgModeNameCardButtonType.callphoneButton
                                    .text &&
                                "tel:+886"
                              }
                              placeholder={
                                item.action?.label === "聯絡電話"
                                  ? `按鈕 ${buttonIndex + 1} 連結輸入您的手機號碼`
                                  : `按鈕 ${buttonIndex + 1} 連結輸入您的按鈕連結`
                              }
                              onChange={(e) => {
                                console.log(e.target.value);
                                onFlexButtonUrlChangeHandler(
                                  currentPage,
                                  hasImageInthisBubble(
                                    nameCardV3Data[currentPage]
                                  )
                                    ? buttonIndex + 1
                                    : buttonIndex,
                                  isPhoneButton(item)
                                    ? handlePhoneNumber(e.target.value, false)
                                    : e.target.value
                                );
                              }}
                            />
                          </Form.Item>
                          <div>
                            <Button
                              size="small"
                              className={classes.deleteFlexButton}
                              onClick={() =>
                                deleteButtonHandler(
                                  hasImageInthisBubble(
                                    nameCardV3Data[currentPage]
                                  )
                                    ? buttonIndex + 1
                                    : buttonIndex
                                )
                              }
                            >
                              X
                            </Button>
                          </div>
                        </div>
                      )
                  )}

                <div className={`${classes.addButtonBlock}`}>
                  <div
                    className={`${classes.defaultButtonContainer} ${classes.flexCol}`}
                  >
                    <Button
                      type="primary"
                      className={classes.defaultButton}
                      onClick={() => addButton(currentPage, willAddButtonType)}
                    >
                      <div className={classes.buttonWord}>新增按鈕</div>
                    </Button>
                  </div>
                  <div
                    className={`${classes.selectInputItem} ${classes.flexCol}`}
                  >
                    <Select
                      size="middle"
                      defaultValue={"urlButton"}
                      onChange={(
                        e: "urlButton" | "phoneButton" | "shareNameCardButton"
                      ) => setWillAddButtonType(e)}
                    >
                      <Option value={"urlButton"}>超連結按鈕</Option>
                      <Option value={"phoneButton"}>手機電話按鈕</Option>
                      <Option value={"shareNameCardButton"}>
                        分享名片按鈕
                      </Option>
                    </Select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Col>

      {/* 因應手機版版面順序跳整固這麼做 */}
      {/* 多新增一和電腦版一樣的頁面，手機版才會顯示，並將電腦版的頁面display none */}
      {/* 利用 css order 屬性控制頁面順序  */}
      <Col lg={16} md={24} sm={24} xs={24} span={16}>
        <Form
          form={form}
          layout="vertical"
          className={classes.formMobile}
          style={{ fontSize: "16px" }}
          initialValues={{
            ...nameCardV3Setting,
            ...nameCardV3Data[currentPage].body?.contents
              .filter((flexComponent) => flexComponent.type !== "image")
              .reduce((acc, item, buttonIndex) => {
                if (item.type === "box" && item.action?.type === "uri") {
                  return {
                    ...acc,
                    [`button${currentPage}-${buttonIndex}-Text`]:
                      item.action.label,
                    [`button${currentPage}-${buttonIndex}-${isPhoneButton(item) ? "PhoneNumber" : "Url"}`]:
                      item.action.uri,
                  };
                }
                return acc;
              }, {}),
          }}
        >
          <Col ref={infoBlockRef} className={classes.controlBarMobile}>
            <div className={classes.cardTitle}>
              {nameCardV3Setting.cardTitle}
            </div>
            {role >= 2 || eagle ? <CategorySelector /> : null}
            <Form.Item
              name="cardTitle"
              label={<span className={classes.label}>電子名片名稱</span>}
              rules={[
                {
                  required: true,
                  validator: async (_: unknown, value: string) => {
                    if (!value) {
                      return Promise.reject("請輸入電子名片名稱");
                    }
                    // 檢查是否包含任何空白字元
                    if (/\s/.test(value)) {
                      return Promise.reject("電子名片名稱不可包含空白字元");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type="text"
                className={classes.input}
                placeholder="請輸入電子名片名稱 "
                onChange={(e) => cardTitleHandler(e.target.value)}
                defaultValue={nameCardV3Setting && nameCardV3Setting.cardTitle}
                value={nameCardV3Setting && nameCardV3Setting.cardTitle}
              />
            </Form.Item>

            <Form.Item
              name="altText"
              label={<span className={classes.label}>LINE 訊息通知文字</span>}
              rules={[
                {
                  required: true,
                  validator: async (_: unknown, value: string) => {
                    if (!value) {
                      return Promise.reject(
                        "請輸入要顯示在 LINE 的訊息通知文字"
                      );
                    }
                    // 檢查是否包含任何空白字元
                    if (/\s/.test(value)) {
                      return Promise.reject("訊息通知文字不可包含空白字元");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input
                type="text"
                className={classes.input}
                placeholder="請輸入要顯示在 LINE 的訊息通知文字"
                onChange={(e) => altTextHandler(e.target.value)}
                defaultValue={nameCardV3Setting.altText}
                value={nameCardV3Setting && nameCardV3Setting.altText}
              />
            </Form.Item>

            <Form.Item
              className={classes.formItem}
              label={<span className={classes.label}>切換頁面</span>}
            >
              <div className={classes.flexRow}>
                <div
                  className={`${classes.defaultButtonContainer} ${classes.flexCol}`}
                >
                  <Button
                    onClick={addPage}
                    type="primary"
                    className={classes.defaultButton}
                  >
                    <div className={classes.buttonWord}>新增頁面</div>
                  </Button>
                </div>
                <div
                  className={`${classes.selectInputItem} ${classes.flexCol}`}
                >
                  <Select
                    value={currentPage}
                    onChange={(e) => handleChangePage(e)}
                  >
                    {nameCardV3Data.map((_, index) => (
                      <Select.Option key={index} value={index}>
                        頁面 {index + 1}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {nameCardV3Data.length > 1 && (
                  <div
                    className={`${classes.defaultButtonContainer} ${classes.originButtonHover} ${classes.flexCol}`}
                  >
                    <Button
                      className={`${classes.defaultButton} ${classes.originButton}`}
                      type="primary"
                      onClick={showDeletePageModal}
                    >
                      <div className={classes.buttonWord}>刪除此頁面</div>
                    </Button>
                  </div>
                )}
              </div>
            </Form.Item>
            {/* </Form> */}
          </Col>

          <Col
            // lg={16}
            // md={24}
            // sm={24}
            // xs={24}
            // span={16}
            className={classes.previewBlock}
            ref={previewBlockRef}
          >
            <canvas width={2000} height={780} ref={canvasRef}></canvas>
            <div className={classes.mobileControl}>
              <div
                onClick={() =>
                  bodyBlockRef &&
                  scrollToPositionUpDown("up", bodyBlockRef, currentPage)
                }
              >
                <Button>⬆︎</Button>
              </div>
              <div
                onClick={() =>
                  bodyBlockRef &&
                  scrollToPositionUpDown("down", bodyBlockRef, currentPage)
                }
              >
                <Button>⬇︎</Button>
              </div>
            </div>
          </Col>
          <Col ref={pageEditBlockRef} className={classes.pageEditerMobile}>
            {/* <Form layout="vertical" style={{ fontSize: "16px" }}> */}
            {/* <Form.Item> */}

            <div className={classes.pageEditerTitle}>
              <div>
                <span>頁面編輯器</span>
              </div>
              <div>{"</>"}</div>
            </div>
            <div className={classes.pagepadding}>
              {/* 背景圖上傳 */}
              <Form.Item
                label={<span className={classes.label}>背景圖上傳</span>}
              >
                <Upload {...props}>
                  <Button
                    className={classes.uploadButton}
                    icon={<UploadOutlined rev={""} />}
                  >
                    上傳圖片
                  </Button>
                </Upload>
              </Form.Item>

              {/* 目前選取的連結按鈕 URL 連結 */}
              <Form.Item
                label={
                  <span className={classes.label}>
                    目前選取的連結按鈕 URL 連結
                  </span>
                }
              >
                <Input
                  type="text"
                  disabled={isShareNameCardButton}
                  onChange={(e) => {
                    setBtnUrl(e.target.value);
                  }}
                  value={btnUrl ? handlePhoneNumber(btnUrl, true) : ""}
                ></Input>
              </Form.Item>

              <div className={classes.buttonEditBlock}>
                {nameCardV3Data[currentPage].body?.contents
                  .filter(
                    (flexComponent, flexComponentIndex) =>
                      flexComponent.type !== "image"
                  )
                  .map(
                    (item, buttonIndex) =>
                      item.type === "box" && (
                        <div
                          className={classes.flexMsgButtonItem}
                          key={`currentPage-${currentPage}-buttonIndex-${buttonIndex}`}
                        >
                          <Form.Item
                            className={`${classes.flexCol} ${classes.formItem}`}
                            label={<div>{`按鈕 ${buttonIndex + 1} 文字`}</div>}
                            name={`button${currentPage}-${buttonIndex}-Text`}
                            rules={[
                              {
                                required: true,
                                validator: async (
                                  _: unknown,
                                  value: string
                                ) => {
                                  if (!value) {
                                    return Promise.reject("請輸入按鈕文字");
                                  }
                                  // 檢查是否包含任何空白字元
                                  if (/\s/.test(value)) {
                                    return Promise.reject(
                                      "按鈕文字不可包含空白字元"
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              disabled={
                                (item.action &&
                                  item.action.type === "uri" &&
                                  item.action.label ===
                                    fullImgModeNameCardButtonLabelConfig.shareNameCardButton) ||
                                (item.contents &&
                                  item.contents.length > 0 &&
                                  item.contents[0].type === "text" &&
                                  (item.contents[0].text ===
                                    fullImgModeNameCardButtonType
                                      .shareNameCardButton.text ||
                                    item.contents[0].text ===
                                      fullImgModeNameCardButtonType
                                        .callphoneButton.text))
                              }
                              type="text"
                              defaultValue={item.action?.label}
                              value={item.action?.label}
                              onChange={(e) => {
                                console.log(e.target.value);
                                onFlexButtonLabelChangeHandler(
                                  currentPage,
                                  hasImageInthisBubble(
                                    nameCardV3Data[currentPage]
                                  )
                                    ? buttonIndex + 1
                                    : buttonIndex,
                                  e.target.value
                                );
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            className={`${classes.flexCol} ${classes.formItem}`}
                            label={<div>{`按鈕 ${buttonIndex + 1} 連結`}</div>}
                            name={
                              isPhoneButton(item)
                                ? `button${currentPage}-${buttonIndex}-PhoneNumber`
                                : `button${currentPage}-${buttonIndex}-Url`
                            }
                            rules={[
                              {
                                required: true,
                                validator: async (
                                  _: unknown,
                                  value: string
                                ) => {
                                  if (!value) {
                                    return Promise.reject(
                                      isPhoneButton(item)
                                        ? "請輸入電話號碼"
                                        : "請輸入按鈕連結"
                                    );
                                  }
                                  if (isPhoneButton(item)) {
                                    // 移除可能的前綴以進行驗證
                                    const phoneNumber = value.replace(
                                      /^(\+886|0)/,
                                      ""
                                    );

                                    // 台灣手機: 9開頭後面8碼
                                    const mobilePattern = /^9\d{8}$/;
                                    // 市話: 區碼1-2碼 + 電話號碼6-8碼
                                    const telPattern = /^[2-8]\d{7,8}$/;
                                    // 0800服務電話: 移除0後為800
                                    const servicePattern = /^800\d{6}$/;

                                    if (
                                      !mobilePattern.test(phoneNumber) &&
                                      !telPattern.test(phoneNumber) &&
                                      !servicePattern.test(phoneNumber)
                                    ) {
                                      return Promise.reject(
                                        "請輸入有效的台灣電話號碼格式"
                                      );
                                    }
                                  } else if (!isValidHttpUrl(value)) {
                                    return Promise.reject("請輸入有效的 URL");
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <Input
                              disabled={
                                item.action &&
                                item.action.type === "uri" &&
                                item.action.label ===
                                  fullImgModeNameCardButtonLabelConfig.shareNameCardButton &&
                                item.contents &&
                                item.contents.length > 0 &&
                                item.contents[0].type === "text" &&
                                item.contents[0].text ===
                                  fullImgModeNameCardButtonType
                                    .shareNameCardButton.text
                              }
                              type={
                                item.action &&
                                item.action.type === "uri" &&
                                item.action.label ===
                                  fullImgModeNameCardButtonLabelConfig.phoneButton &&
                                item.contents &&
                                item.contents.length > 0 &&
                                item.contents[0].type === "text" &&
                                item.contents[0].text ===
                                  fullImgModeNameCardButtonType.callphoneButton
                                    .text
                                  ? "number"
                                  : "text"
                              }
                              defaultValue={
                                item.action?.type === "uri"
                                  ? handlePhoneNumber(item.action.uri, true)
                                  : ""
                              }
                              value={
                                item.action?.type === "uri"
                                  ? handlePhoneNumber(item.action.uri, true)
                                  : ""
                              }
                              addonBefore={
                                item.action &&
                                item.action.type === "uri" &&
                                item.action.label ===
                                  fullImgModeNameCardButtonLabelConfig.phoneButton &&
                                item.contents &&
                                item.contents.length > 0 &&
                                item.contents[0].type === "text" &&
                                item.contents[0].text ===
                                  fullImgModeNameCardButtonType.callphoneButton
                                    .text &&
                                "tel:+886"
                              }
                              placeholder={
                                item.action?.label === "聯絡電話"
                                  ? `按鈕 ${buttonIndex + 1} 連結輸入您的手機號碼`
                                  : `按鈕 ${buttonIndex + 1} 連結輸入您的按鈕連結`
                              }
                              onChange={(e) => {
                                console.log("e.target.value", e.target.value);
                                onFlexButtonUrlChangeHandler(
                                  currentPage,
                                  hasImageInthisBubble(
                                    nameCardV3Data[currentPage]
                                  )
                                    ? buttonIndex + 1
                                    : buttonIndex,
                                  // handlePhoneNumber(e.target.value, false)
                                  isPhoneButton(item)
                                    ? handlePhoneNumber(e.target.value, false)
                                    : e.target.value
                                );
                              }}
                            />
                          </Form.Item>
                          <div>
                            <Button
                              size="small"
                              className={classes.deleteFlexButton}
                              onClick={() =>
                                deleteButtonHandler(
                                  hasImageInthisBubble(
                                    nameCardV3Data[currentPage]
                                  )
                                    ? buttonIndex + 1
                                    : buttonIndex
                                )
                              }
                            >
                              X
                            </Button>
                          </div>
                        </div>
                      )
                  )}

                <div className={`${classes.addButtonBlock}`}>
                  <div
                    className={`${classes.defaultButtonContainer} ${classes.flexCol}`}
                  >
                    <Button
                      type="primary"
                      className={classes.defaultButton}
                      onClick={() => addButton(currentPage, willAddButtonType)}
                    >
                      <div className={classes.buttonWord}>新增按鈕</div>
                    </Button>
                  </div>
                  <div
                    className={`${classes.selectInputItem} ${classes.flexCol}`}
                  >
                    <Select
                      size="middle"
                      defaultValue={"urlButton"}
                      onChange={(
                        e: "urlButton" | "phoneButton" | "shareNameCardButton"
                      ) => setWillAddButtonType(e)}
                    >
                      <Option value={"urlButton"}>超連結按鈕</Option>
                      <Option value={"phoneButton"}>手機電話按鈕</Option>
                      <Option value={"shareNameCardButton"}>
                        分享名片按鈕
                      </Option>
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            {/* </Form.Item> */}
          </Col>
        </Form>
      </Col>

      <Modal
        className={classes.modalStyle}
        open={isOpenDeletePageModal}
        onCancel={handleDeletePageModalCancel}
        footer={false}
        width={300}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <p>提示</p>
          <p>是否確定要刪除頁面 {`${currentPage + 1}`} ?</p>
          <div className={classes.buttonBlock}>
            <div className={classes.buttonItem}>
              <Button
                className={classes.modalButton}
                type="default"
                onClick={() => handleDeletePageModalCancel()}
              >
                否
              </Button>
            </div>
            <div className={classes.buttonItem}>
              <Button
                type="primary"
                className={classes.modalButton + " " + classes.modalButtonBlue}
                onClick={() => deletePage(currentPage)}
              >
                是
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        className={classes.modalStyle}
        open={isOpenDeleteButtonModal}
        onCancel={handleDeleteButtonModalCancel}
        footer={false}
        width={300}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <p>提示</p>
          <p>是否確定要刪除目前選的按鈕</p>

          <div className={classes.buttonBlock}>
            <div className={classes.buttonItem}>
              <Button
                className={classes.modalButton}
                type="default"
                onClick={handleDeleteButtonModalCancel}
              >
                否
              </Button>
            </div>
            <div className={classes.buttonItem}>
              <Button
                type="primary"
                className={classes.modalButton + " " + classes.modalButtonBlue}
                onClick={() =>
                  buttonIndex !== null && deleteButton(currentPage, buttonIndex)
                }
              >
                是
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title="會籍權限通知"
        open={isPermissionMsgModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        付費升級為正式會員才可使用多頁名片功能！
      </Modal>
    </Row>
  );
};

export default CreateFullImgModeNameCard;
