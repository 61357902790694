import { useNavigate } from "react-router-dom";

import classes from "../Header.module.sass";
import { useEffect, useState } from "react";
import NameCardTypeModal from "../../SideBar/NameCardTypeModal";
import profileIconSelect from "../../../image/icon-profile-select.svg";
import profileIconNomal from "../../../image/icon-profile-nomal.svg";
import userBonusIconSelect from "../../../image/icon-user-bonus-select.svg"; // 24.11.02 SCH ★
import userBonusIconNomal from "../../../image/icon-user-bonus-nomal.svg"; // 24.11.02 SCH ★
import {
  FolderFilled,
  PlusOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import CancelEditModal from "../../SideBar/CancelEditModal";

import {
  nameCardOperationStateType,
  pageModeType,
  useAppStateStore,
} from "../../../store/appState";

import { Modal, Button, Form, Input, message } from "antd"; // 24.11.04 SCH ★

import { format } from "date-fns"; // 24.11.04 SCH ★

type inputHandelerType = {
  key: "Y4M2" | string; // 24.11.05 SCH
  value: string;
};

const NameCardsListHeader = () => {
  const navigate = useNavigate();
  const { PAGEMODE, NAMECARD_OPERATION, NavListHeader } = useAppStateStore();
  const { currentPageModeState, setCurrentPageModeState } = PAGEMODE;
  const { nameCardOperationState, setNameCardOperationState } =
    NAMECARD_OPERATION;
  const { selectNameCardType, action } = nameCardOperationState;
  const { isOpen, toggle, close } = NavListHeader;

  const [nameCardTypeModalopen, setNameCardTypeModalOpen] =
    useState<boolean>(false);
  const [cancelEditModalOpen, setCancelEditModalOpen] =
    useState<boolean>(false);
  const [routerPath, setRouterPath] = useState<string>("/");
  const [icon, SetIcon] = useState(classes.nav__toggler);
  const [active, setActive] = useState(classes.navbar);

  const { BRAND, USER, USER_BONUS } = useAppStateStore(); // 24.11.04 SCH
  const { brandState } = BRAND; // 24.11.01 SCH ★
  const { userState } = USER; // 24.10.31 SCH
  const { role, eagle, enable, trial_due_at, mobile_number, name_card_count } =
    userState; // 24.11.05 SCH
  const [trialDueAt, setTrialDueAt] = useState(""); // 24.11.01 SCH ★
  const [isAddBanModalOpen, setIsAddBanModalOpen] = useState(false); // 24.11.05 SCH ★
  const [isEditBanModalOpen, setIsEditBanModalOpen] = useState(false); // 24.11.02 SCH ★
  const [isUserBonusBanModalOpen, setIsUserBonusBanModalOpen] = useState(false); // 24.11.02 SCH ★
  const [isBonusY4M2ModalOpen, setIsBonusY4M2ModalOpen] = useState(false); // 24.11.04 SCH ★
  const { userBonusState, setUserBonusState } = USER_BONUS; // 24.11.04 SCH ★
  const [isValidateY4M2, setIsValidateY4M2] = useState<boolean>(true); // 24.11.07 SCH ★

  // 打開 NameCardsListHeader nav
  const openNav = () => {
    setActive(classes.navbar + " " + classes.navbar__active);
    SetIcon(classes.nav__toggler + " " + classes.toggle);
  };
  // 關閉 NameCardsListHeader nav
  const closeNav = () => {
    setActive(classes.navbar);
    SetIcon(classes.nav__toggler);
  };

  // 切換 NameCardsListHeader nav 開閉
  useEffect(() => {
    if (isOpen) {
      openNav();
    } else {
      closeNav();
    }
  }, [isOpen]);

  const closeNameCardTypeModalHandler = () => {
    setNameCardTypeModalOpen(false);
    setNameCardOperationState({ selectNameCardType: "null" });
  };

  /**
   * 開啟頁面 Function
   * @param {string} targetPage - 要跳轉的目標頁面
   * @param {string[]} pagesThatRequireModal - 需要顯示取消編輯框的頁面
   * @param {string} action - 目前執行的是跳轉到哪個頁面操作
   **/
  const navigateTo = (
    targetPage: pageModeType,
    pagesThatRequireModal: pageModeType[],
    action: nameCardOperationStateType["action"] | undefined
  ) => {
    if (currentPageModeState === targetPage) return;

    if (pagesThatRequireModal.includes(currentPageModeState)) {
      setCancelEditModalOpen(true);
      setRouterPath(`/${targetPage}`);
      setNameCardOperationState({ action: action ?? "null" });
    } else {
      navigate(`/${targetPage}`);
    }
  };

  /**
   * 開啟名片列表頁面的Function
   **/
  const nameCardOnClickHandler = () => {
    navigateTo(
      "nameCardsList",
      [
        "createNameCard",
        "updateNameCard",
        "createNameCardFullImgMode",
        "updateNameCardFullImgMode",
        "profile",
      ],
      "goNameCardList"
    );
    // 關閉 NameCardsListHeader nav
    close();
  };

  /**
   * 開啟名片類型框的Function
   **/
  const openNameCardTypeModalHandler = () => {
    if (
      [
        "createNameCard",
        "createNameCardFullImgMode",
        "updateNameCard",
        "updateNameCardFullImgMode",
      ].includes(currentPageModeState)
    ) {
      return setCancelEditModalOpen(true);
    }

    setNameCardTypeModalOpen(true);

    if (selectNameCardType === "null") return;
    if (selectNameCardType === "v1.0") {
      navigate("/createNameCard");
    } else if (selectNameCardType === "v3.0") {
      navigate("/createFullImgMode");
    }
  };

  /**
   * 新增名片按鈕的Function
   * @param {number} nameCardCount - 目前名片數量
   * @param {number} maxAddableNameCards - 此會員可以新增的名片數量
   * @param {number} role - 會員權限
   * @param {number} [allowedRole=2] - 允許的權限
   * @param {boolean} enable - 是否可以新增名片
   * @param {boolean} eagle - 是否是老鷹推廣夥伴  
   * @param {string | null} mobileNumber - 會員手機號碼

   **/
  const handleAddNameCardClick = (
    nameCardCount: number,
    maxAddableNameCards: number,
    role: number,
    allowedRole: number = 2,
    enable: number,
    eagle: number,
    mobileNumber: string | null,
    maxAddableNameCard3: number
  ) => {
    // 目前名片數量大於此會員可以新增的名片數量
    if (nameCardCount >= maxAddableNameCards) {
      setIsEditBanModalOpen(true);
    }
    // 是付費會員或可新增名片權限或老鷹推廣夥伴
    else if (role >= allowedRole || enable || eagle) {
      openNameCardTypeModalHandler();
      setRouterPath("/");
      setNameCardOperationState({ action: "reCreate" });
    } else {
      // 個人資料是否有填手機號碼 // 24.12.08 SCH ★
      if (brandState.trial_name_cards === 0) window.location.reload();
      // 25.01.02 SCH ★ add ( && userState.name_card_count >= brandState.trial_name_card3)
      if (
        (mobileNumber === null || mobileNumber === "") &&
        userState.name_card_count >= brandState.trial_name_card3
      ) {
        setIsAddBanModalOpen(true);
      } else {
        setIsEditBanModalOpen(true);
      }
    }
  };

  /**
   * 開啟個人資料頁面的Function
   **/
  const profileOnClickHandler = () => {
    navigateTo(
      "profile",
      [
        "createNameCard",
        "updateNameCard",
        "createNameCardFullImgMode",
        "updateNameCardFullImgMode",
      ],
      "goProfile"
    );
  };

  // 24.11.05 SCH ★
  /**
   * 開啟佣金頁面的Function
   **/
  const userBonusOnClickHandler = () => {
    navigateTo(
      "userBonus",
      [
        "profile",
        "createNameCard",
        "createNameCardFullImgMode",
        "updateNameCard",
        "updateNameCardFullImgMode",
      ],
      "goUserBonus"
    );

    if (
      [
        "profile",
        "createNameCard",
        "createNameCardFullImgMode",
        "updateNameCard",
        "updateNameCardFullImgMode",
      ].includes(currentPageModeState)
    ) {
      setUserBonusState({ isUserBonus: true });
    }
  };

  /**
   * 開啟自訂分類頁面的Function
   **/
  const handleCustomCategoriesClick = () => {
    if (role < 2 && !eagle) {
      message.error("您沒有權限使用此功能，可在個人資料頁面升級成付費會員");
      return;
    }
    navigateTo(
      "customCategories",
      [
        "profile",
        "createNameCard",
        "createNameCardFullImgMode",
        "updateNameCard",
        "updateNameCardFullImgMode",
      ],
      "goCustomCategories"
    );
  };

  // const profileOnClickHandler = () => {
  //   if (currentPageModeState === "profile") return;
  //   if (
  //     currentPageModeState === "nameCardsList" ||
  //     currentPageModeState === "userBonus"
  //   )
  //     return navigate("/profile");
  //   if (
  //     currentPageModeState === "createNameCard" ||
  //     currentPageModeState === "createNameCardFullImgMode" ||
  //     currentPageModeState === "updateNameCard" ||
  //     currentPageModeState === "updateNameCardFullImgMode"
  //   ) {
  //     setCancelEditModalOpen(true);
  //     setRouterPath("/profile");
  //     setNameCardOperationState({ action: "goProfile" });
  //   }
  // };

  // // 24.11.05 SCH ★
  // const userBonusOnClickHandler = () => {
  //   if (currentPageModeState === "userBonus") return;
  //   if (currentPageModeState === "nameCardsList") return navigate("/userBonus");
  //   if (
  //     currentPageModeState === "profile" ||
  //     currentPageModeState === "createNameCard" ||
  //     currentPageModeState === "createNameCardFullImgMode" ||
  //     currentPageModeState === "updateNameCard" ||
  //     currentPageModeState === "updateNameCardFullImgMode"
  //   ) {
  //     setCancelEditModalOpen(true);
  //     setUserBonusState({ isUserBonus: true }); // 24.11.05 SCH ★
  //     setRouterPath("/userBonus");
  //     setNameCardOperationState({ action: "goUserBonus" });
  //   }
  // };

  // const nameCardOnClickHandler = () => {
  //   if (currentPageModeState === "nameCardsList") return;
  //   if (currentPageModeState === "userBonus") return navigate("/");
  //   if (
  //     currentPageModeState === "createNameCard" ||
  //     currentPageModeState === "createNameCardFullImgMode" ||
  //     currentPageModeState === "updateNameCard" ||
  //     currentPageModeState === "updateNameCardFullImgMode" ||
  //     currentPageModeState === "profile"
  //   ) {
  //     setCancelEditModalOpen(true);
  //     setRouterPath("/");
  //     setNameCardOperationState({ action: "goNameCardList" });
  //   }
  // };

  const inputHandeler = ({ key, value }: inputHandelerType) => {
    switch (key) {
      case "Y4M2": // 24.11.07 SCH ★
        validateY4M2(value) && setUserBonusState({ y4m2: value });
        console.log("inputHandeler.y4m2 =", value);
        break;

      default:
        break;
    }
  };

  const y4m2_Rule = /^([0-9])*[0-9]+-([0-9])*[0-9]$/; // 24.11.05 SCH ★

  const validateY4M2 = (value: string) => {
    // slice(2,4) === substring(2,4); (the end byte not taked！)
    let y2 = value.slice(2, 4); // 24.11.05 SCH ★
    console.log("y2 =", y2); // 24.11.05 SCH ★
    if (
      value &&
      value.length === 7 &&
      value[4] === "-" &&
      value.search(y4m2_Rule) !== -1 &&
      value.slice(0, 2) === "20" &&
      y2 >= "23" &&
      y2 <= "99" &&
      value.slice(5) >= "01" &&
      value.slice(5) <= "12"
    ) {
      setIsValidateY4M2(true);
      console.log("isValidateY4M2 =", true);
      return true; // 24.11.05 SCH ★
    } else {
      if (!value || value.length === 0) {
        setIsValidateY4M2(true);
        console.log("isValidateY4M2 =", true);
        return true; // 24.11.14 SCH ★
      } else {
        setIsValidateY4M2(false);
        console.log("isValidateY4M2 =", false);
        return false; // 24.11.05 SCH ★
      }
    }
  };

  const closeCancelEditModalHandler = () => {
    setCancelEditModalOpen(false);
    setNameCardOperationState({ selectNameCardType: "null" });
  };

  const reCreateEventModalHandler = () => {
    setNameCardTypeModalOpen(true);
  };

  const AddBanHandleOk = () => {
    setIsAddBanModalOpen(false);
  };

  const AddBanHandleCancel = () => {
    setIsAddBanModalOpen(false);
  };

  const EditBanHandleOk = () => {
    setIsEditBanModalOpen(false);
  };

  const EditBanHandleCancel = () => {
    setIsEditBanModalOpen(false);
  };

  const UserBonusBanHandleOk = () => {
    setIsUserBonusBanModalOpen(false);
  };

  const UserBonusBanHandleCancel = () => {
    setIsUserBonusBanModalOpen(false);
  };

  const BonusY4M2HandleOk = () => {
    setIsBonusY4M2ModalOpen(false);
    userBonusOnClickHandler(); // 24.11.04 SCH
  };

  const BonusY4M2HandleCancel = () => {
    setIsBonusY4M2ModalOpen(false);
    setUserBonusState({ y4m2: undefined }); // 24.11.04 SCH
  };

  useEffect(() => {
    let trialDueAt = trial_due_at?.toString();
    if (trialDueAt) setTrialDueAt(trialDueAt); // 24.11.01 SCH ★
    let now_month = format(new Date(), "yyyy-MM");
    setUserBonusState({ y4m2: now_month }); // 24.11.07 SCH ★
  }, [trialDueAt, trial_due_at, setUserBonusState]);

  useEffect(() => {
    console.log("selectedNameCardType", selectNameCardType);
    if (selectNameCardType === "null") return;
    if (selectNameCardType === "v1.0") {
      navigate("/createNameCard");
    } else if (selectNameCardType === "v3.0") {
      navigate("/createFullImgMode");
    }
  }, [selectNameCardType]);

  return (
    <>
      <div className={classes.navBar}>
        <NameCardTypeModal
          isOpen={nameCardTypeModalopen}
          closeNameCardTypeModalHandler={closeNameCardTypeModalHandler}
        />
        <CancelEditModal
          isOpen={cancelEditModalOpen}
          routerPath={routerPath}
          closeCancelEditModalHandler={closeCancelEditModalHandler}
          reCreateEventModalHandler={reCreateEventModalHandler}
        />
        <div className={active}>
          <div className={classes.text} onClick={nameCardOnClickHandler}>
            <div
              className={` ${classes.buttonDefault}  ${
                currentPageModeState === "nameCardsList"
                  ? classes.selectColor
                  : classes.nomalColor
              } `}
            >
              <FolderFilled rev={""} />
            </div>
            <div className={classes.label}>全部名片</div>
          </div>
          <div
            className={classes.text}
            onClick={() => {
              handleAddNameCardClick(
                name_card_count,
                brandState.trial_name_cards,
                role,
                2,
                enable,
                eagle,
                mobile_number,
                brandState.trial_name_card3
              );
              // 關閉 NameCardsListHeader nav
              close();
            }}
          >
            <div
              className={` ${classes.buttonDefault}  ${
                currentPageModeState === "createNameCard" ||
                currentPageModeState === "createNameCardFullImgMode" ||
                currentPageModeState === "updateNameCard" ||
                currentPageModeState === "updateNameCardFullImgMode"
                  ? classes.selectColor
                  : classes.nomalColor
              } `}
            >
              <PlusOutlined rev={""} />
            </div>
            <div className={classes.label}>新增名片</div>
          </div>
          <div
            className={classes.text}
            onClick={() => {
              profileOnClickHandler();
              // 關閉 NameCardsListHeader nav
              close();
            }}
          >
            <div className={classes.buttonDefault}>
              <img
                src={
                  currentPageModeState === "profile"
                    ? profileIconSelect
                    : profileIconNomal
                }
                alt="profile"
              />
            </div>
            <div className={classes.label}>個人資料</div>
          </div>
          <div
            className={classes.text}
            onClick={() => {
              // 當點選自己的頁面不會轉跳
              if (currentPageModeState === "userBonus") return;
              if (role >= 2 || eagle) {
                setIsBonusY4M2ModalOpen(true); // 24.11.04 SCH
              } else {
                setIsUserBonusBanModalOpen(true); // 24.11.02 SCH
              }
              // 關閉 NameCardsListHeader nav
              close();
            }}
          >
            <div className={classes.buttonDefault}>
              <img
                src={
                  currentPageModeState === "userBonus"
                    ? userBonusIconSelect
                    : userBonusIconNomal
                }
                alt="userBonus"
              />
            </div>
            <div className={classes.label}>推廣佣金</div>
          </div>
          <div
            className={classes.text}
            onClick={() => {
              handleCustomCategoriesClick();
              // 關閉 NameCardsListHeader nav
              close();
            }}
          >
            <div
              className={` ${classes.buttonDefault}  ${
                currentPageModeState === "customCategories"
                  ? classes.selectColor
                  : classes.nomalColor
              } `}
            >
              <AppstoreAddOutlined rev={""} />
            </div>
            <div className={classes.label}>自訂分類</div>
          </div>
        </div>
        <div onClick={toggle} className={icon}>
          <div className={classes.line1}></div>
          <div className={classes.line2}></div>
          <div className={classes.line3}></div>
        </div>
      </div>

      <Modal
        title="編輯權益限制提醒"
        open={isAddBanModalOpen}
        onOk={AddBanHandleOk}
        onCancel={AddBanHandleCancel}
      >
        <p>無法新增名片，您尚未完成輸入個人資料喔！</p>
      </Modal>

      <Modal
        title="編輯權益限制提醒"
        open={isEditBanModalOpen}
        onOk={EditBanHandleOk}
        onCancel={EditBanHandleCancel}
      >
        <p>
          無法新增名片，您現持有張數已滿免費試用數（
          {`${brandState.trial_name_cards}`}）喔！
        </p>
      </Modal>

      <Modal
        title="查詢權益限制提醒"
        open={isUserBonusBanModalOpen}
        onOk={UserBonusBanHandleOk}
        onCancel={UserBonusBanHandleCancel}
      >
        <p>
          您是一般會員，無聯盟行銷推廣佣金資料喔！您可在〔個人資料〕的頁面看到〈付費解鎖進階功能〉的按鈕。
        </p>
      </Modal>

      <Modal
        title="指定佣金所屬年月"
        open={isBonusY4M2ModalOpen}
        onOk={BonusY4M2HandleOk}
        onCancel={BonusY4M2HandleCancel}
      >
        <div>
          <Form.Item
            label={<span>佣金所屬年月：</span>}
            validateStatus={isValidateY4M2 ? "success" : "error"}
            hasFeedback
          >
            <Input
              type="text"
              name="Y4M2"
              allowClear
              placeholder="請輸入佣金所屬年月：yyyy-mm"
              defaultValue={format(new Date(), "yyyy-MM")}
              onChange={(e) => {
                inputHandeler({ key: e.target.name, value: e.target.value });
              }}
            ></Input>
            <p></p>
            <p>清空上列欄位內容，則顯示全部資料。</p>
          </Form.Item>
        </div>
      </Modal>
    </>
  );
};

export default NameCardsListHeader;
