import { useEffect } from "react";
import { Typography } from "antd";
import classes from "./PaymentTerms.module.sass";
import { useAppStateStore } from "../../../store/appState";

const PaymentTerms = () => {
  const { Link } = Typography;
  const { PAGEMODE } = useAppStateStore();
  const { setCurrentPageModeState } = PAGEMODE;

  useEffect(() => {
    setCurrentPageModeState("null");
  }, []);

  return (
    <div className={classes.termsContainer}>
      <h1>IP Flow 付款及退款條款</h1>
      <div className={classes.termsContent}>
        <h3>1. 付款條款</h3>
        <h4>1.1 付款方式</h4>
        <ul>
          <li>本網站接受信用卡、金融卡及其他電子支付方式進行付款。</li>
          <li>所有價格均以新台幣計價，並已包含相關稅費。</li>
        </ul>
        <h4>1.2 購買方案</h4>
        <ul>
          <li>
            目前提供用戶可升級三年方案，若未來有提供其他付款方案會另行通知。
          </li>
          <li>目前系統僅提供購買方案，三年到期則需另外購買。</li>
        </ul>

        <h3>2. 退款政策</h3>
        <h4>2.1 退款條件</h4>
        <ul>
          <li>
            <strong>
              若因價格調整，或者本服務終止，將依以下程序進行退款作業。
            </strong>
          </li>
        </ul>
        <h4>2.2 不予退款情況</h4>
        <ul>
          <li>
            依照行政院消費者保護法規定，本服務適用「通訊交易解除權合理
            例外情事適用準則」第五項「非以有形媒介提供之數位內容或一經提供
            即為完成之線上服務，經消費者事先同意始提供。」應排除7日解除
            權，且已有提供單頁式電子名片供用戶使用，用戶在已了解本系統功能
            後才升級付費方案，取得製作多頁式電子名片資格，因此購買後恕不退款。
          </li>
          <li>
            相關法規說明連結：
            <Link
              href="https://cpc.ey.gov.tw/Page/BC16ACF0BBB9CCC2/f8e15c56-0cbe-49ac-a7ba-2f41e1d9ca55"
              target="_blank"
              rel="noopener noreferrer"
            >
              <strong>
                https://cpc.ey.gov.tw/Page/BC16ACF0BBB9CCC2/f8e15c56-0cbe-49ac-a7ba-
                2f41e1d9ca55
              </strong>
            </Link>
          </li>
        </ul>
        <h4>2.3 退款程序</h4>
        <ul>
          <li>用戶需透過客服系統提出退款申請。</li>
          <li>需提供購買憑證及退款原因。</li>
          <li>
            退款將於申請審核通過後的 <strong>5-7 個工作天</strong> 內處理。
          </li>
        </ul>

        <h3>3. 服務變更與終止</h3>
        <h4>3.1 價格調整</h4>
        <ul>
          <li>本公司保留調整服務價格的權利。</li>
          <li>
            價格調整將提前 <strong>30天</strong> 通知現有用戶。
          </li>
          <li>若用戶不同意新價格，可選擇終止服務並依比例退還未使用費用。</li>
        </ul>
        <h4>3.2 服務終止</h4>
        <ul>
          <li>用戶可隨時終止服務。</li>
          <li>已繳費用將依未使用時間比例退還。</li>
          <li>
            終止後的帳戶資料將保留 <strong>30天</strong> ，逾期自動刪除。
          </li>
        </ul>

        <h3>4. 其他規定</h3>
        <h4>4.1 稅務處理</h4>
        <ul>
          <li>所有交易將開立發票。</li>
          <li>用戶需提供正確的發票開立資訊。</li>
        </ul>
        <h4>4.2 爭議處理</h4>
        <ul>
          <li>
            任何付款爭議應於發現後 <strong>30天</strong> 內提出。
          </li>
          <li>
            本公司將於收到爭議通知後的 <strong>15 個工作天</strong> 內回覆。
          </li>
        </ul>

        <h3>5. 條款修改</h3>
        <ul>
          <li>本公司保留修改本條款的權利。</li>
          <li>條款修改將透過電子郵件通知用戶。</li>
          <li>
            重大修改將提前 <strong>30天</strong> 通知。
          </li>
        </ul>

        <strong>最後更新日期 : 2025 年 1 月 7 日</strong>
      </div>
    </div>
  );
};
export default PaymentTerms;
