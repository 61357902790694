import { useRef, useState } from "react";
import { CheckOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Modal,
  message,
  Typography,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  newebpay,
  INewebpay,
  INewbpayPreviewSuccessResponse,
  INewbpayPreviewInfo,
  INewbpayNotPreviewFreeSuccessResponse,
} from "../../../../api/payment";
import { planType } from "../../../../api";
import classes from "./PaymentModal.module.sass";

const { Link } = Typography;

type PaymentModalProps = {
  open: boolean;
  onCancel: () => void;
  planList?: planType[];
  userState: any;
  profileInputData: any;
  isValidatePhoneNumber: boolean;
  isValidateEmail: boolean;
};

/**
 * 取得折扣文字
 * @param discount_num 折扣數字
 * @param original_price 原價
 * @param discount_amount 折扣後價格
 * @returns 折扣文字
 */
const getDiscountText = (
  discount_num: number,
  original_price: number,
  discount_amount: number
) => {
  if (original_price === discount_amount) return "免費優惠方案";
  if (discount_num === 0.9) return "九折優惠方案";
  if (discount_num === 0.8) return "八折優惠方案";
  if (discount_num === 0.7) return "七折優惠方案";
  if (discount_num === 0.6) return "六折優惠方案";
  if (discount_num === 0.5) return "五折優惠方案";
  return "無";
};

const PaymentModal = ({
  open,
  onCancel,
  planList,
  userState,
  profileInputData,
  isValidatePhoneNumber,
  isValidateEmail,
}: PaymentModalProps) => {
  const [previewInfo, setPreviewInfo] = useState<INewbpayPreviewInfo | null>(
    null
  );
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [hasReadTerms, setHasReadTerms] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<planType | null>(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const termsRef = useRef<HTMLDivElement>(null);
  const [showPayment, setShowPayment] = useState(false);

  const Newebpay = async ({
    plan_id,
    mobile_number,
    email,
    preview_mode,
    coupon_code,
  }: INewebpay): Promise<{ success: boolean; message?: string }> => {
    try {
      if (!userState)
        return { success: false, message: "付款所需會員資料尚未填寫" };
      const { accessToken } = userState;
      if (!accessToken) return { success: false, message: "無效的 token" };
      const { data, error: payError } = await newebpay(
        accessToken,
        plan_id,
        mobile_number,
        email,
        preview_mode,
        coupon_code
      );

      if (data) {
        if (preview_mode) {
          const { data: res } = data as INewbpayPreviewSuccessResponse;
          setPreviewInfo(res);
          if (res.message === "freeRedeemed") {
            navigate("/freePlanRedeemSuccessPage", { replace: true });
            return { success: true, message: "免費方案兌換成功" };
          }
          return { success: true };
        } else {
          if (typeof data === "string") {
            const formContainer = document.createElement("div");
            const submitButton = document.createElement("button");
            submitButton.type = "submit";
            submitButton.textContent = "Submit";
            submitButton.id = "submit";
            formContainer.innerHTML = data;
            const submitFrom = formContainer.querySelector(
              "#order-form"
            ) as HTMLFormElement;
            if (submitFrom) {
              document.body.appendChild(submitFrom);
              submitFrom.appendChild(submitButton);
            }
            (submitFrom.querySelector("#submit") as HTMLButtonElement).click();
            return { success: true };
          } else {
            const { data: res } = data as INewbpayNotPreviewFreeSuccessResponse;
            if (res.message === "freeRedeemed") {
              navigate("/freePlanRedeemSuccessPage", { replace: true });
              return { success: true, message: "免費方案兌換成功" };
            }
          }
        }
      }

      if (payError) {
        console.log(payError);
        if (payError.response?.status === 404) {
          const { message: errorMsg } = payError.response.data as unknown as {
            message: string;
          };
          if (errorMsg === "coupon_code not found.")
            message.warn("折扣碼無效", 5); // 24.12.04 SCH ★
          return { success: false, message: "折扣碼無效" };
        }
        if (payError.response?.status === 403) {
          const { message: errorMsg } = payError.response.data as unknown as {
            message: string;
          };
          if (errorMsg === "coupon_code had been used.") {
            message.warn("折扣碼已被使用過", 5); // 24.12.04 SCH ★
            return { success: false, message: "折扣碼已被使用過" };
          }
          if (errorMsg === "please check the plan_id.") {
            message.warn(
              "折扣碼不適用於您選擇的付費方案，請再確認折扣碼適用的方案，並在該方案中使用",
              5
            );
            return {
              success: false,
              message: "折扣碼不適用於此方案",
            };
          }
        }
      }
      return { success: false, message: "付款處理失敗" };
    } catch (error) {
      console.log(error);
      return { success: false, message: "系統錯誤" };
    }
  };

  /**
   * 付款處理
   * @param previewMode 預覽模式
   * @param planID 方案ID
   */
  const payHandler = async (previewMode: 1 | null, planID: number | null) => {
    const values = form.getFieldsValue();
    const { mobile_number, email, coupon_code } = values;
    if (planID === null) return message.warn("請選擇方案", 5);
    if (!mobile_number || !email)
      return message.warn("請輸入正確的聯絡資料", 5);

    const result = await Newebpay({
      plan_id: planID,
      mobile_number: mobile_number,
      email: email,
      preview_mode: previewMode,
      coupon_code: coupon_code,
    });
    // 成功轉跳到第三方付款頁面, 初始化 選擇方案, 預覽資訊, 同意條款 等狀態
    if (result.success) {
      setSelectedPlan(null);
      setPreviewInfo(null);
      setIsAgreed(false);
      setHasReadTerms(false);
    }
  };

  /**
   * 付款表單提交
   * @param values 表單值
   * @param previewMode 預覽模式
   * @param planID 方案ID
   */
  const payFormSubmit = async (
    values: {
      mobile_number: string;
      email: string;
      coupon_code: string;
    },
    _preview_mode: 1 | null,
    planID: number | null
  ) => {
    const { mobile_number, email, coupon_code } = values;

    if (planID === null) return message.warn("請選擇方案", 5);
    if (!mobile_number || !email)
      return message.warn("請輸入正確的聯絡資料", 5);

    const result = await Newebpay({
      plan_id: planID,
      mobile_number: mobile_number,
      email: email,
      preview_mode: _preview_mode,
      coupon_code: coupon_code,
    });
    // 成功取得付款預覽資訊, 開啟付款資訊表單
    if (result.success) {
      setShowPayment(true);
    }
  };

  /**
   * 條款閱讀狀態更新
   */
  const handleScroll = () => {
    if (termsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
      if (scrollHeight - scrollTop - clientHeight < 30) {
        setHasReadTerms(true);
      }
    }
  };

  /**
   * 選擇方案
   * @param plan 方案
   */
  const handlePlanSelect = (plan: planType) => {
    setSelectedPlan(plan);
  };

  /**
   * 確認購買
   */
  const handleConfirmPurchase = () => {
    setShowConfirmation(true);
  };

  const handleSelectPlanModalCancel = () => {
    setSelectedPlan(null);
    setPreviewInfo(null);
    setIsAgreed(false);
    setHasReadTerms(false);
    if (termsRef.current) {
      termsRef.current.scrollTop = 0;
    }
    onCancel();
  };

  return (
    <div>
      <Modal
        open={open}
        onCancel={handleSelectPlanModalCancel}
        footer={null}
        width={800}
        className={classes.planModal}
        title="選擇付費方案"
      >
        {planList && planList.length > 0 ? (
          <div>
            <div className={classes.planCards}>
              {planList.map((plan) => (
                <Card
                  key={`plan-${plan.plan_id}`}
                  onClick={() => handlePlanSelect(plan)}
                  className={`${classes.planCard} ${selectedPlan?.plan_id === plan.plan_id ? classes.selected : ""}`}
                >
                  <h3>{plan.name}</h3>
                  <p>{plan.description}</p>
                  <p className={classes.price}>NTD {plan.original_price}</p>
                  <div
                    className={`${classes.selectionStatus} ${selectedPlan?.plan_id === plan.plan_id ? classes.selected : ""}`}
                  >
                    {selectedPlan?.plan_id === plan.plan_id
                      ? "已選擇"
                      : "點擊選擇"}
                  </div>
                </Card>
              ))}
            </div>

            <div className={classes.termsContainer}>
              <h3>IP Flow 付款及退款條款</h3>
              <div
                ref={termsRef}
                onScroll={handleScroll}
                className={classes.termsContent}
              >
                <h3>1. 付款條款</h3>
                <h4>1.1 付款方式</h4>
                <ul>
                  <li>本網站接受信用卡、金融卡及其他電子支付方式進行付款。</li>
                  <li>所有價格均以新台幣計價，並已包含相關稅費。</li>
                </ul>
                <h4>1.2 購買方案</h4>
                <ul>
                  <li>
                    目前提供用戶可升級三年方案，若未來有提供其他付款方案會另行通知。
                  </li>
                  <li>目前系統僅提供購買方案，三年到期則需另外購買。</li>
                </ul>

                <h3>2. 退款政策</h3>
                <h4>2.1 退款條件</h4>
                <ul>
                  <li>
                    <strong>
                      若因價格調整，或者本服務終止，將依以下程序進行退款作業。
                    </strong>
                  </li>
                </ul>
                <h4>2.2 不予退款情況</h4>
                <ul>
                  <li>
                    依照行政院消費者保護法規定，本服務適用「通訊交易解除權合理
                    例外情事適用準則」第五項「非以有形媒介提供之數位內容或一經提供
                    即為完成之線上服務，經消費者事先同意始提供。」應排除7日解除
                    權，且已有提供單頁式電子名片供用戶使用，用戶在已了解本系統功能
                    後才升級付費方案，取得製作多頁式電子名片資格，因此購買後恕不退款。
                  </li>
                  <li>
                    相關法規說明連結：
                    <Link
                      href="https://cpc.ey.gov.tw/Page/BC16ACF0BBB9CCC2/f8e15c56-0cbe-49ac-a7ba-2f41e1d9ca55"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <strong>
                        https://cpc.ey.gov.tw/Page/BC16ACF0BBB9CCC2/f8e15c56-0cbe-49ac-a7ba-
                        2f41e1d9ca55
                      </strong>
                    </Link>
                  </li>
                </ul>
                <h4>2.3 退款程序</h4>
                <ul>
                  <li>用戶需透過客服系統提出退款申請。</li>
                  <li>需提供購買憑證及退款原因。</li>
                  <li>
                    退款將於申請審核通過後的 <strong>5-7 個工作天</strong>{" "}
                    內處理。
                  </li>
                </ul>

                <h3>3. 服務變更與終止</h3>
                <h4>3.1 價格調整</h4>
                <ul>
                  <li>本公司保留調整服務價格的權利。</li>
                  <li>
                    價格調整將提前 <strong>30天</strong> 通知現有用戶。
                  </li>
                  <li>
                    若用戶不同意新價格，可選擇終止服務並依比例退還未使用費用。
                  </li>
                </ul>
                <h4>3.2 服務終止</h4>
                <ul>
                  <li>用戶可隨時終止服務。</li>
                  <li>已繳費用將依未使用時間比例退還。</li>
                  <li>
                    終止後的帳戶資料將保留 <strong>30天</strong>{" "}
                    ，逾期自動刪除。
                  </li>
                </ul>

                <h3>4. 其他規定</h3>
                <h4>4.1 稅務處理</h4>
                <ul>
                  <li>所有交易將開立發票。</li>
                  <li>用戶需提供正確的發票開立資訊。</li>
                </ul>
                <h4>4.2 爭議處理</h4>
                <ul>
                  <li>
                    任何付款爭議應於發現後 <strong>30天</strong> 內提出。
                  </li>
                  <li>
                    本公司將於收到爭議通知後的 <strong>15 個工作天</strong>{" "}
                    內回覆。
                  </li>
                </ul>

                <h3>5. 條款修改</h3>
                <ul>
                  <li>本公司保留修改本條款的權利。</li>
                  <li>條款修改將透過電子郵件通知用戶。</li>
                  <li>
                    重大修改將提前 <strong>30天</strong> 通知。
                  </li>
                </ul>

                <strong>最後更新日期 : 2025 年 1 月 7 日</strong>
              </div>

              <Checkbox
                className={classes.checkbox}
                checked={isAgreed}
                onChange={(e) => setIsAgreed(e.target.checked)}
                disabled={!hasReadTerms}
              >
                我已經閱讀並同意上述{" "}
                <Link
                  href="/doc/paymentTerms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  IP Flow 付款及退款條款。{" "}
                </Link>
                {!hasReadTerms && (
                  <span style={{ color: "#ff4d4f" }}>(請先閱讀完整條款)</span>
                )}
              </Checkbox>
            </div>
          </div>
        ) : (
          <div>
            <p>目前沒有任何付費方案</p>
          </div>
        )}

        <div className={classes.buttonContainer}>
          <Button onClick={handleSelectPlanModalCancel}>取消</Button>
          <Button
            type="primary"
            onClick={handleConfirmPurchase}
            disabled={!isAgreed || !selectedPlan}
            icon={<CheckOutlined rev={""} className={classes.checkOutlined} />}
          >
            確認購買
          </Button>
        </div>
      </Modal>

      <Modal
        open={showConfirmation}
        onCancel={() => setShowConfirmation(false)}
        footer={null}
        className={classes.confirmationModal}
        title="確認購買資訊"
        width={400}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) =>
            payFormSubmit(values, 1, selectedPlan?.plan_id ?? null)
          }
          initialValues={{
            mobile_number:
              profileInputData.mobile_number ?? userState.mobile_number,
            email: profileInputData.email ?? userState.email,
            coupon_code: userState.coupon_code ?? "",
          }}
        >
          <Form.Item
            name="mobile_number"
            label="聯絡電話"
            rules={[{ required: true, message: "請輸入聯絡電話" }]}
          >
            <Input
              placeholder="請輸入聯絡電話"
              disabled={isValidatePhoneNumber}
              name="mobile_number"
            />
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "請輸入 Email" },
              { type: "email", message: "請輸入有效的 Email" },
            ]}
          >
            <Input
              placeholder="請輸入 Email"
              disabled={isValidateEmail}
              name="email"
            />
          </Form.Item>

          <Form.Item name="coupon_code" label="折扣碼">
            <Input placeholder="選填" />
          </Form.Item>

          {selectedPlan && (
            <div className={classes.planInfo}>
              <h4>方案資訊</h4>
              <p>{selectedPlan.name}</p>
              <p>{selectedPlan.description}</p>
              <p className={classes.price}>NTD {selectedPlan.original_price}</p>
            </div>
          )}

          <div className={classes.confirmationModal}>
            <div className={classes.buttonContainer}>
              <Button onClick={() => setShowConfirmation(false)}>返回</Button>

              <Button
                type="primary"
                htmlType="submit"
                icon={
                  <CheckOutlined rev={""} className={classes.checkOutlined} />
                }
              >
                確認
              </Button>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        open={showPayment}
        onCancel={() => setShowPayment(false)}
        footer={null}
        className={classes.paymentModal}
        title="付款資訊"
      >
        {previewInfo && (
          <div className={classes.paymentInfo}>
            <div>
              方案原價：
              {`$ ${previewInfo.original_price.toLocaleString()} 元`}
            </div>
            <div>
              折扣方案：
              {getDiscountText(
                previewInfo.discount_num,
                previewInfo.original_price,
                previewInfo.discount_amount
              )}
            </div>

            <div>
              折扣金額：
              {`$ ${previewInfo.discount_amount.toLocaleString()} 元`}
            </div>
            <div>
              付款金額：
              {`$ ${(
                previewInfo.original_price - previewInfo.discount_amount
              ).toLocaleString()} 元`}
            </div>
            <div className={classes.paymentAction}>
              <Button
                type="primary"
                onClick={() => {
                  console.log("Processing payment...");
                  onCancel();
                  setShowPayment(false);
                  setShowConfirmation(false);
                  payHandler(null, selectedPlan?.plan_id ?? null);
                }}
              >
                確認付款
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default PaymentModal;
