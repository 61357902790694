import { useEffect, useState } from "react";
import { message } from "antd";

import { memberCheck, UserCheckResponse, sendAuthCode } from "../../api";
import { useAppStateStore } from "../../store/appState";
import classes from "./MemberCheck.module.sass";

import VerificationForm from "./VerificationForm";
import ResultsDisplay from "./ResultsDisplay";

export type infotype = {
  realname: string;
  mobileNumber: string;
  email: string; // 24.12.03 SCH ★
  authCode: string;
};

const MemberCheck = () => {
  const { USER, PAGEMODE, MEMBERCHECK, BRAND } = useAppStateStore();
  const { userState } = USER;
  const { brandState } = BRAND; // 24.11.30 SCH ★
  const { accessToken } = userState;
  const { setIsMemberCheck } = MEMBERCHECK;
  const { setCurrentPageModeState } = PAGEMODE;
  const [res, setRes] = useState<UserCheckResponse | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);

  /**
   * member check api
   * @param _accessToken user access token
   * @param _mobileNumber user mobile number
   * @param _realname user realname
   * @param _email user email
   */
  const memberCheckApi = async (
    _accessToken: string,
    _mobileNumber: string,
    _realname: string,
    _email: string,
    _authCode: string
  ) => {
    console.log("authCode", _authCode);
    try {
      const { data, error } = await memberCheck(
        _accessToken,
        _mobileNumber,
        _realname,
        _email,
        _authCode
      );
      if (error) {
        const { response } = error;
        if (response) {
          const { data: errorRes } = response;
          const { message: errorMsg } = errorRes;
          if (errorMsg === "authentication failed!") {
            return message.error("驗證碼錯誤，請重新輸入");
          } else {
            return message.error("發生錯誤，請重新操作");
          }
        }
      }
      setRes(data);
      setIsMemberCheck(true);
    } catch (error) {
      console.log("Error", error);
    }
  };

  /**
   * commit check user info
   * @param values
   */
  const handleSubmit = async (values: infotype) => {
    console.log("values!!!", values);
    const { mobileNumber, realname, email, authCode } = values;
    if (!values) return message.error("請檢查輸入內容");
    if (
      !mobileNumber ||
      mobileNumber.trim().length !== 10 ||
      mobileNumber[0] !== "0"
    ) {
      console.log(mobileNumber.trim().length !== 10);
      return message.error("請檢查手機號碼內容");
    }

    if (!realname || realname.trim().length < 1) {
      return message.error("請檢查姓名輸入內容，不得留空");
    }

    if (accessToken && values) {
      memberCheckApi(accessToken, mobileNumber, realname, email, authCode);
    }
  };

  /**
   * get auth code
   * @param values
   */
  const getAuthCode = async (values: infotype) => {
    if (!accessToken || !values.mobileNumber) return;
    try {
      const { data, error } = await sendAuthCode(
        accessToken,
        brandState.id,
        values.mobileNumber,
        "M"
      );
      if (data && !error) {
        console.log("data", data); // 24.12.17 SCH ★
        // data [0908715876-2024-12-17-14-53-52]
        // msgid=1390139877
        // statuscode=1 // 因有三竹簡訊 response 四行資料插進來, 造成無法正常取出"auth_lead"的值(value)
        // AccountPoint=288
        // {"success":true,"message":"send auth code successfully","auth_code":null,"auth_lead":"IP-FLOW-AUTH-CODE:"}
        const { auth_lead } = data;
        console.log("auth_lead", auth_lead);
        if (auth_lead === undefined) {
          message.info("IP-FLOW-AUTH-CODE:", 7);
        } else {
          message.info(auth_lead, 7);
        }
        return message.success("驗證碼已發送");
      }

      if (error) {
        setLoading(false);
        setCountdown(0);
        const { response } = error;
        if (response) {
          const { data: errorRes } = response;
          const { message: errorMsg } = errorRes;
          if (response.status === 422) {
            if (errorMsg === "The given data was invalid.") {
              return message.error("送出的資料無效，請檢查輸入內容");
            }
          }
          if (response.status === 403) {
            if (JSON.stringify(errorRes).includes("send auth code failed.")) {
              return message.error("發送驗證碼失敗");
            }
          }
        } else {
          return message.error("發生錯誤，請重新操作");
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setCurrentPageModeState("userCheck");
  }, []);

  return (
    <div className={classes.container}>
      <VerificationForm
        onSubmit={handleSubmit}
        getAuthCode={getAuthCode}
        getAuthCodeCountdownTime={300}
        sendAuthCodeLoading={loading}
        sendAuthCodeCountdownTime={countdown}
        setSendAuthCodeLoading={setLoading}
        setSendAuthCodeCountdownTime={setCountdown}
      />
      <ResultsDisplay data={res?.data} />
    </div>
  );
};
export default MemberCheck;
